import { all, put, takeEvery } from "@redux-saga/core/effects";
import { errorHandler } from "../../services/error";
import LogRocket from "logrocket";
import store from "../store/store";

export const actionTypes = {
  SET_ERROR_STATUS: "SET_ERROR_STATUS",
  SET_SUCCESS_STATUS: "SET_SUCCESS_STATUS",
  CLEAR_STATUS: "CLEAR_STATUS"
};

export const statusActions = {
  setErrorStatus: (payload) => {
    store.dispatch({
      type: actionTypes.SET_ERROR_STATUS,
      payload: payload
    });
  },

  setSuccessStatus: (message) => {
    store.dispatch({
      type: actionTypes.SET_SUCCESS_STATUS,
      payload: {
        message: message,
        code: "success"
      }
    });
  },

  removeStatus: () => {
    store.dispatch({
      type: actionTypes.CLEAR_STATUS
    });
  }
};

function* setErrorStatusWorker(action) {
  try {
    //! need google analytics
    // window.gtag("event", "exception", {
    //   code: action.payload.code,
    //   userType: "patients",
    //   message: action.payload.message,
    //   fatal: false // set to true if the error is fatal
    // });

    LogRocket.captureMessage(action.payload.message, {
      tags: {
        userType: "patients"
      },
      extra: {
        fatal: false,
        code: action.payload.code
      }
    });

    const error = errorHandler(action.payload);
    yield put({
      type: "SET_STATUS",
      payload: {
        code: error.code,
        message: error.message
      }
    });
    setTimeout(() => {
      statusActions.removeStatus();
    }, 4000);
  } catch (error) {
    console.error(error);
  }
}

function* setSuccessStatusWorker(action) {
  try {
    yield put({
      type: "SET_STATUS",
      payload: {
        code: null,
        message: action.payload.message
      }
    });
    setTimeout(() => {
      statusActions.removeStatus();
    }, 4000);
  } catch (error) {}
}

function* setStatusResponseWorker() {
  yield put({
    type: "REMOVE_STATUS"
  });
}

export function* statusWatcher() {
  yield all([
    takeEvery("SET_ERROR_STATUS", setErrorStatusWorker),
    takeEvery("SET_SUCCESS_STATUS", setSuccessStatusWorker),
    takeEvery("CLEAR_STATUS", setStatusResponseWorker)
  ]);
}
