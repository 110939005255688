import React from "react";
import "./ProgressTrack.css";
import {
  CircleWithTickIcon,
  CalenderIcon,
  DeleteIcon
  // DownloadIcon
} from "../../assets/assets";
import RoundedProfilePicture from "../RoundedProfilePicture/RoundedProfilePicture";
import { dateAndTimeConverter } from "../../utils/constants";
import Button from "../Button/Button";
import pdfIcon from "../../assets/PdfIcon.svg";
// import SliderButton from "../SliderButton/SliderButton";

export default function ProgressTrack(props) {
  function noOfStepsCompleted() {
    let value;
    switch (props.data?.status) {
      case "REQUESTED":
        if (props.userType === "clinic") {
          return (value = "");
        } else {
          value = "1";
        }
        break;

      case "APPOINTED":
        if (props.userType === "clinic") {
          return (value = "1");
        } else if (props.userType === "corporate") {
          return (value = "2");
        } else {
          value = "2";
        }
        break;

      case "COMPLETED":
        if (props.userType === "clinic") {
          if (props.data?.documentId) {
            return (value = "3");
          } else {
            return (value = "2");
          }
        } else if (props.userType === "corporate") {
          if (props.data?.documentId) {
            return (value = "4");
          } else {
            return (value = "3");
          }
        } else {
          if (props.data?.documentId && props.data?.shareReportWithUser) {
            return (value = "4");
          } else {
            value = "3";
          }
        }
        break;
      default:
        value = "1";
        break;
    }
    return value;
  }

  const getHeight = () => {
    if (!props.data?.appointedAt) {
      return "height-80px";
    } else if (
      props.data?.appointedAt &&
      props.data?.status !== "COMPLETED" &&
      props.userType === "employee"
    ) {
      return "height-200px";
    } else {
      return "height-150px";
    }
  };

  return (
    <>
      <div className="font-size-small font-family-gilroy-regular margin-top-small margin-top-large flex-justify-content-space-between">
        <div>TRACK PROGRESS</div>
        <div className=" font-color-primary display-flex">
          <div data-cy="progress-number">{`${
            noOfStepsCompleted() === 3 && props.data?.documentId
              ? "4"
              : noOfStepsCompleted()
          }`}</div>
          <div data-cy="total-progress-number">{`/${props.options.length}`}</div>
        </div>
      </div>

      <div className=" margin-top-large height-fit-content adaptive-request-height hide-scrollbar">
        {props.options.includes("requestReceived") && (
          <ProgressCard
            type="requestReceived"
            connection={props.connection}
            removeLine={props.options.includes("appointment")}
            userType={props.userType}
            completed={props.data?.requestedAt ? true : false}
            data={props.data}
            height="height-40px"
          />
        )}

        <div
          className={`${
            props.options.includes("requestReceived") &&
            "transform-translate_0px_-5px"
          }`}
        >
          {props.options.includes("appointment") && (
            <ProgressCard
              type="appointment"
              connection={props.connection}
              removeLine={props.options.includes("healthCheckup")}
              userType={props.userType}
              completed={props.data?.appointedAt ? true : false}
              data={props.data}
              height={`${getHeight()}`}
              bookAppointmentOnclick={props.bookAppointmentOnclick}
              reScheduleOnclick={props.reScheduleOnclick}
            />
          )}
        </div>

        <div
          className={`${
            props.options.includes("requestReceived")
              ? "transform-translate_0px_-10px"
              : props.options.includes("appointment") &&
                "transform-translate_0px_-5px"
          }`}
        >
          {props.options.includes("healthCheckup") && (
            <ProgressCard
              type="healthCheckup"
              connection={props.connection}
              removeLine={props.options.includes("report")}
              userType={props.userType}
              completed={props.data?.status === "COMPLETED" ? true : false}
              healthCheckUpSliderStatus={props.healthCheckUpSliderStatus}
              healthCheckupOnClick={props.healthCheckupOnClick}
              setCompletedStatus={props.setCompletedStatus}
              data={props.data}
              height={`${
                props.data?.status === "COMPLETED"
                  ? "height-45px"
                  : props.userType === "clinic"
                  ? "height-120px"
                  : ""
              }`}
            />
          )}
        </div>

        <div className="transform-translate_0px_-15px">
          {props.options.includes("report") && (
            <ProgressCard
              type="reportReady"
              connection={props.connection}
              isDownloadUrlPresent={props.isDownloadUrlPresent}
              pdfOnclick={props.pdfOnclick}
              deleteOnclick={props.deleteOnclick}
              userType={props.userType}
              completed={props.data?.status === "COMPLETED" ? true : false}
              onFileUploadOnChange={props.onFileUploadOnChange}
              data={props.data}
            />
          )}
        </div>
      </div>
    </>
  );
}

function ProgressCard(props) {
  return (
    <div>
      <div className=" height-fit-content display-flex flex-direction-row">
        <div
          data-cy={`${
            props.completed
              ? `completed-circle-${props.type}`
              : `incomplete-circle-${props.type}`
          }`}
          className="flex-direction-column "
        >
          <CircleWithTickIcon
            height="16"
            width="16"
            circleColor="white"
            circleOutline={props.completed ? "#4E4E4E" : "#E5E5E5"}
            tickColor={
              props.type === "reportReady"
                ? props.completed && props.data?.documentId
                  ? "#4E4E4E"
                  : ""
                : props.completed
                ? "#4E4E4E"
                : ""
            }
          />
          {props.type === "reportReady" ? (
            ""
          ) : (
            <div
              className={`${props.height} width-16px flex-direction-column flex-center-children-vertically transform-translate_0px_-5px`}
            >
              {props.removeLine && (
                <div
                  className={`${
                    props.completed
                      ? "background-color-4E4E4E"
                      : "background-color-E5E5E5"
                  } flex-grow width-1px border-radius-default`}
                />
              )}
              {props.removeLine && !props.completed && (
                <>
                  <div
                    className={`${
                      props.completed
                        ? "background-color-4E4E4E"
                        : "background-color-E5E5E5"
                    } height-5px width-1px margin-top-small border-radius-default`}
                  />
                  <div
                    className={`${
                      props.completed
                        ? "background-color-4E4E4E"
                        : "background-color-E5E5E5"
                    } height-5px width-1px margin-top-small border-radius-default`}
                  />
                  <div
                    className={`${
                      props.completed
                        ? "background-color-4E4E4E"
                        : "background-color-E5E5E5"
                    } height-5px width-1px margin-top-small border-radius-default`}
                  />
                </>
              )}
            </div>
          )}
        </div>

        <div className="inherit-parent-width">
          <div className="font-family-gilroy-regular flex-justify-content-space-between">
            <div className="margin-left-default font-size-small height-16px display-flex flex-align-items-center">
              {props.type === "requestReceived" ? (
                props.userType === "corporate" ? (
                  <>REQUEST SENT</>
                ) : (
                  <>REQUEST RECEIVED</>
                )
              ) : props.type === "appointment" ? (
                <>APPOINTMENT</>
              ) : props.type === "healthCheckup" ? (
                <>{`HEALTH CHECK UP ${
                  props.completed === true ? "DONE" : ""
                }`}</>
              ) : props.type === "reportReady" ? (
                <>
                  {props.data?.documentId
                    ? "REPORT READY"
                    : props.userType === "clinic"
                    ? "UPLOAD DOCUMENT"
                    : "REPORT READY"}
                </>
              ) : (
                ""
              )}
            </div>
            {props.type === "requestReceived" ? (
              <Time date={props.data?.requestedAt} />
            ) : props.type === "appointment" ? (
              <Time date={props.data?.appointmentFixedAt} />
            ) : props.type === "healthCheckup" ? (
              <Time date={props.data?.completedAt} />
            ) : props.type === "reportReady" ? (
              <Time date={props.data?.reportedAt} />
            ) : (
              ""
            )}
          </div>

          {props.type === "appointment" &&
            !props.completed &&
            props.userType === "employee" && (
              <div className="padding-default">
                <Button
                  data-cy="appointment-button"
                  boxShadow={false}
                  autofocus={true}
                  text="Book appointment"
                  onClick={props.bookAppointmentOnclick}
                />
              </div>
            )}

          {props.type === "appointment" && props.completed && (
            <div>
              <div className=" margin-left-default margin-bottom-large margin-top-small flex-center-children-vertically">
                {props.userType === "corporate" && (
                  <RoundedProfilePicture
                    src={
                      props.connection?.clinicBrandLogo &&
                      props.connection.clinicBrandLogo[props.data.clinicBrandId]
                    }
                    size="medium"
                    removeResponsive={true}
                  />
                )}

                {props.userType === "clinic" && (
                  <RoundedProfilePicture
                    src={
                      props.connection?.brands &&
                      props.connection.brands[props.data.clinicBrandId]
                        ?.downloadURL
                    }
                    size="medium"
                    removeResponsive={true}
                  />
                )}

                {props.userType === "employee" && (
                  <RoundedProfilePicture
                    src={
                      props.connection?.clinicsLogo &&
                      props.connection.clinicsLogo[props.data.clinicBrandId]
                        ?.url
                    }
                    size="medium"
                    removeResponsive={true}
                  />
                )}
                <div className="font-family-gilroy-regular  margin-left-default">
                  <div className="font-size-medium">
                    {props.data?.clinicName}
                  </div>
                  <div className="font-size-smaller">
                    {props.data?.clinicLocality}
                  </div>
                </div>
              </div>
              <div className=" margin-left-default  flex-center-children-vertically">
                <div className=" height-width-44px background-color-grey border-radius-50-percentage border-2px-E5E5E5 flex-place-children-page-center">
                  <CalenderIcon color="#4E4E4E" height="17.5" width="17.5" />
                </div>
                <div className="font-family-gilroy-regular margin-left-default">
                  <div className="font-size-medium">
                    {dateAndTimeConverter(
                      props.data?.appointedAt,
                      "secondDate"
                    )}
                  </div>
                </div>
              </div>
              {props.data?.status !== "COMPLETED" &&
                props.userType === "employee" && (
                  <div className=" font-family-gilroy-regular margin-left-default font-size-medium margin-top-medium margin-bottom-medium">
                    Want your appointment on another date or hospital?{" "}
                    <span
                      className=" font-color-primary font-family-gilroy-medium white-space-nowrap text-underline-hover cursor-pointer"
                      onClick={props.reScheduleOnclick}
                    >
                      Re-schedule appointment
                    </span>
                  </div>
                )}
            </div>
          )}

          {props.type === "healthCheckup" && props.userType === "clinic" && (
            <div>
              <div className="margin-left-default margin-bottom-large margin-top-default text-capitalize transform-translate_0px_-5px">
                {props.data?.description}
              </div>
              {props.data?.status === "APPOINTED" && (
                <div className="margin-default padding-bottom-large text-capitalize">
                  <Button
                    data-cy="complete-health-checkup-button"
                    boxShadow={false}
                    autofocus={true}
                    text="Complete health checkup"
                    onClick={props.healthCheckupOnClick}
                  />
                  {/* <SliderButton
                    completed={props.healthCheckUpSliderStatus}
                    setCompleted={props.setCompletedStatus}
                  /> */}
                </div>
              )}
            </div>
          )}

          {props.userType === "employee" &&
            props.type === "reportReady" &&
            props.data?.shareReportWithUser &&
            props.completed &&
            props.isDownloadUrlPresent &&
            props.data?.documentId && (
              <div className=" margin-left-default">
                <div
                  data-cy="pdf-icon"
                  onClick={props.pdfOnclick}
                  className=" height-fit-content width-fit-content  cursor-pointer border-2px-E5E5E5 border-radius-default margin-top-default "
                >
                  <img
                    draggable="false"
                    className=" height-width-144px padding-left-larger padding-right-larger padding-top-larger padding-bottom-larger"
                    src={pdfIcon}
                    alt="pdf icon"
                  />
                </div>
                {props.userType === "clinic" && (
                  <div
                    data-cy="delete-pdf-icon"
                    onClick={props.deleteOnclick}
                    className="delete-icon-position height-width-32px margin-default background-color-lite-grey border-radius-100-percentage flex-center-children-horizontally flex-center-children-vertically"
                  >
                    <DeleteIcon />
                  </div>
                )}
              </div>
            )}

          {props.userType !== "employee" &&
            props.type === "reportReady" &&
            props.completed &&
            props.isDownloadUrlPresent &&
            props.data?.documentId && (
              <div className=" margin-left-default">
                <div
                  data-cy="pdf-icon"
                  onClick={props.pdfOnclick}
                  className=" height-fit-content width-fit-content  cursor-pointer border-2px-E5E5E5 border-radius-default margin-top-default "
                >
                  <img
                    draggable="false"
                    className=" height-width-144px padding-left-larger padding-right-larger padding-top-larger padding-bottom-larger"
                    src={pdfIcon}
                    alt="pdf icon"
                  />
                </div>
                {props.userType === "clinic" && (
                  <div
                    data-cy="delete-pdf-icon"
                    onClick={props.deleteOnclick}
                    className="delete-icon-position height-width-32px margin-default background-color-lite-grey border-radius-100-percentage flex-center-children-horizontally flex-center-children-vertically"
                  >
                    <DeleteIcon />
                  </div>
                )}
              </div>
            )}

          {props.type === "reportReady" &&
            props.completed &&
            props.userType === "clinic" &&
            !props.data?.documentId && (
              <label
                htmlFor="uploadFile"
                className="flex-center-children-horizontally padding-top-large flex-center-children-vertically flex-direction-column"
              >
                <div
                  data-cy="upload-document-button"
                  className=" padding-medium inherit-parent-width flex-center-children-horizontally background-color-primary border-radius-default margin-bottom-large text-capitalize font-family-gilroy-medium font-color-white"
                >
                  Upload report
                </div>
                <input
                  type="file"
                  accept=".jpeg,.png,.pdf"
                  name="files"
                  id="uploadFile"
                  className="display-none"
                  onClick={(event) => {
                    event.target.value = "";
                  }}
                  onChange={(event) => {
                    props.onFileUploadOnChange(event);
                  }}
                />
              </label>
            )}
        </div>
      </div>
    </div>
  );
}

function Time(props) {
  return (
    <div className="font-size-smaller text-align-right">
      <div>{dateAndTimeConverter(props.date, "secondDate")}</div>
      <div className=" margin-top-smaller">
        {dateAndTimeConverter(props.date, "Time")}
      </div>
    </div>
  );
}
