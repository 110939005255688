const appReducer = (
  state = {
    online: false
  },
  action
) => {
  switch (action.type) {
    case "SET_ONLINE":
      return {
        online: action.payload.online
      };

    case "RESET_ONLINE_STATUS":
      return {
        online: false
      };

    default:
      return state;
  }
};
export default appReducer;
