const corporateReducer = (
  state = {
    loading: false,
    checkups: null,
    selectedRequest: null,
    availableClinics: null,
    selectedClinic: null,
    clinicsLogo: null,
    corporateBrandLogo: null
  },
  action
) => {
  switch (action.type) {
    case "SET_CORPORATE_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };

    case "SET_CORPORATE_CHECKUPS_DATA":
      return {
        ...state,
        checkups: action.payload.checkups
      };

    case "SET_SELECTED_REQUEST_DATA":
      return {
        ...state,
        selectedRequest: action.payload.data
      };

    case "RESET_SELECTED_REQUEST":
      return {
        ...state,
        selectedRequest: null,
        availableClinics: null
      };

    case "SET_AVAILABLE_CLINICS": {
      return {
        ...state,
        availableClinics: action.payload.clinics
      };
    }

    case "SET_SELECTED_CLINIC_DATA": {
      return {
        ...state,
        selectedClinic: action.payload.selectedClinic
      };
    }

    case "SET_CHECKUP_DOWNLOADABLE_URL":
      return {
        ...state,
        downloadableURLs: {
          ...state.downloadableURLs,
          ...action.payload.data
        }
      };

    case "SET_CLINIC_LOGO": {
      return {
        ...state,
        clinicsLogo: action.payload.clinicBrandId
      };
    }

    case "SET_CORPORATE_LOGO": {
      return {
        ...state,
        corporateBrandLogo: action.payload.corporateBrandLogo
      };
    }

    case "RESET":
      return {
        loading: false,
        checkups: null,
        selectedRequest: null,
        availableClinics: null,
        selectedClinic: null,
        downloadableURLs: null,
        clinicsLogo: null,
        corporateBrandLogo: null
      };
    default: {
      return state;
    }
  }
};

export default corporateReducer;
