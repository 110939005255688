import { mockActions } from "../redux/sagas/mockSaga";
import { api } from "../utils/constants";

//TO GET VERIFICATION CODE FROM FIREBASE EMULATOR
export async function getVerificationCodeFromEmulator() {
  const response = await fetch(
    `http://localhost:9099/emulator/v1/projects/karthealth-dev/verificationCodes`,
    {
      method: "get"
    }
  );
  const result = await response.json();
  if (result.error) {
    throw result.error;
  }
  return result;
}

//TO CREATE SEED DATABASE
export async function createSeedDatabase() {
  const response = await fetch(`${api.baseUrl}/testApi/createSeedProfile`, {
    method: "put"
  });
  const result = await response.json();
  if (result.body.success === true) {
    mockActions.createSeedDatabaseSuccess();
  } else if (result.error) {
    throw result.error;
  }
  return result;
}

//TO DELETE SEED DATABASE
export async function deleteSeedDatabase() {
  const response = await fetch(`${api.baseUrl}/testApi/deleteSeedProfile`, {
    method: "put"
  });
  const result = await response.json();
  if (result.body.success === true) {
    mockActions.deleteSeedDatabaseSuccess();
  } else if (result.error) {
    throw result.error;
  }
  return result;
}
