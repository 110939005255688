import React from "react";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import arrow from "../../front-end-global-components/assets/DropDownArrow.svg";
import add from "../../front-end-global-components/assets/add_grey.svg";

export default function ProfileCards(props) {
  return (
    <>
      {!props.type && (
        <div
          className={
            "inherit-parent-width flex-justify-content-space-between margin-bottom-large  border-radius-default cursor-pointer"
          }
          onClick={props.onCardClick}
        >
          <div className="flex-center-children-vertically">
            <RoundedProfilePicture
              size="medium"
              removeResponsive={true}
              src={props.logo}
            />
            <div className="padding-left-large">
              <div className=" font-size-large padding-right-default">
                {props.title}
              </div>
              <div className=" font-size-small">{props.subTitle}</div>
            </div>
          </div>
          <img className=" rotate-90" src={arrow} alt="arrow" />
        </div>
      )}
      {props.type === "add" && (
        <div
          className="flex-justify-content-space-between flex-center-children-vertically "
          onClick={props.addOnClick}
        >
          <div className=" display-flex flex-center-children-vertically ">
            <div className="background-color-lite-grey border-radius-100-percentage height-and-width-44px flex-place-children-page-center">
              <img src={add} alt="add" />
            </div>
            <div className="font-size-large padding-left-large">
              {props.text}
            </div>
          </div>
          <img className=" rotate-90" src={arrow} alt="arrow" />
        </div>
      )}
    </>
  );
}
