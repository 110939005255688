import React, { useState } from "react";
import { connect } from "react-redux";
import { corporateActions } from "../../redux/sagas/corporateSaga";
import { authActions } from "../../redux/sagas/authSaga";
import Header from "../../front-end-global-components/components/Header/Header";
import { HealthCheckupTimeline } from "../../front-end-global-components/components/HealthCheckupTimeline/HealthCheckupTimeline";
import Button from "../../front-end-global-components/components/Button/Button";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import "./Home.css";

function Home(props) {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const isRequestExpired = (timestamp) => {
    const day = Math.round((timestamp - +new Date()) / 86400000);
    if (day <= 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <div
        data-cy="home-page-content"
        className="inherit-parent-height flex-place-children-page-center"
      >
        <div className="inherit-parent-height inherit-parent-width large-screen-bg-color large-screen-height large-screen-width large-screen-border">
          <Header
            removeResponsive={true}
            userType="corporate"
            headerPositionLeft={true}
            hideBackButton={true}
            maxStepperCount={0}
            currentStepperValue={0}
            supportIconOnClick={() => {}}
            showLogoutIcon={true}
            logoutIconOnClick={() => {
              setShowLogoutModal(true);
            }}
          />

          <div className="remaining-body-height inherit-parent-width flex-direction-column padding-left-larger padding-right-larger overflow-auto hide-scrollbar">
            <div className="inherit-parent-height">
              <ConditionalRender condition={props.corporate.loading}>
                <TimelineSuspense />
              </ConditionalRender>
              <ConditionalRender
                condition={
                  !props.corporate.loading &&
                  props.corporate.checkups &&
                  !props.isNamePresent
                }
              >
                <HealthCheckupTimeline
                  cardType="employee"
                  filter={[]}
                  connection={props.corporate}
                  checkupsData={props.corporate.checkups}
                  cardOnClick={(data) => {
                    if (
                      !isRequestExpired(data.expiringAt) ||
                      (isRequestExpired(data.expiringAt) &&
                        data.status === "COMPLETED")
                    ) {
                      props.setSelectedRequest(data.requestId);
                      props.getAvailableClinics(data.corporateBrandId);
                      props.navigate("/request");
                    }
                  }}
                />
              </ConditionalRender>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showLogoutModal}
        canIgnore={true}
        onClose={() => {
          setShowLogoutModal(!showLogoutModal);
        }}
        position={
          props.windowInnerWidth > 576
            ? ""
            : "position-fixed bottom-0 left-0 right-0"
        }
        width="inherit-parent-width"
        maxWidth={props.windowInnerWidth < 576 ? "" : "max-width-500px"}
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-fit-to-content"
      >
        <div
          data-cy="logout-modal"
          className="background-white padding-larger border-radius-default box-shadow-default font-family-gilroy-regular font-color-secondary "
        >
          <div className="font-family-gilroy-medium text-align-center margin-bottom-larger padding-top-large">
            Are you sure you want to log out?
          </div>

          <div className=" flex-justify-content-space-around padding-top-medium margin-bottom-large">
            <Button
              boxShadow={false}
              data-cy="confirm-logout-button"
              text="Logout"
              type="button"
              variant="danger"
              className=" margin-right-large"
              onClick={() => {
                props.logout();
              }}
            />
            <Button
              boxShadow={false}
              data-cy="logout-cancel-button"
              text="Cancel"
              variant="secondary"
              className=" margin-left-large"
              onClick={() => {
                setShowLogoutModal(false);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    corporate: state.corporate
  };
};

const mapDispatchToProps = function () {
  return {
    setSelectedRequest: (data) => corporateActions.setSelectedRequest(data),
    getAvailableClinics: (corporateBrandId) =>
      corporateActions.getAvailableClinics(corporateBrandId),
    logout: () => authActions.logout()
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

function TimelineSuspense() {
  return (
    <div className=" margin-top-larger inherit-parent-width ">
      <div className="shimmer border-radius-default min-height-70px" />
      {[...Array(10).keys()].map((index) => (
        <div
          key={index}
          className="shimmer margin-top-larger border-radius-default min-height-70px"
        />
      ))}
    </div>
  );
}
