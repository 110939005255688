import { all, put, takeEvery } from "@redux-saga/core/effects";
import store from "../store/store";
import { statusActions } from "./statusSaga";

export const actionTypes = {
  PUT_ONLINE: "PUT_ONLINE"
};

export const appActions = {
  putOnline: (isOnline) => {
    store.dispatch({
      type: actionTypes.PUT_ONLINE,
      payload: {
        online: isOnline
      }
    });
  }
};

function* putOnlineWorker(action) {
  try {
    if (store.getState().app.online !== action.payload.online) {
      yield put({
        type: "SET_ONLINE",
        payload: {
          online: action.payload.online
        }
      });
    }
  } catch (error) {
    statusActions.setErrorStatus(error);
  }
}

export default function* appWatcher() {
  yield all([takeEvery("PUT_ONLINE", putOnlineWorker)]);
}
