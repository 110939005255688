import { api } from "../utils/constants";

//check phone number availability
export async function checkAccountAvailability(phoneNumber) {
  const response = await fetch(
    `${api.baseUrl}/accountExists?phoneNumber=${encodeURIComponent(
      phoneNumber
    )}&type=patient-demographic`,
    {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    throw result.error;
  }
  return result;
}
