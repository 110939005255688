import React, { useState, useEffect, useRef } from "react";
import RoundedProfilePicture from "../RoundedProfilePicture/RoundedProfilePicture";
import "../../design-system.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import arrow from "../../assets/DropDownArrow.svg";
import "./HealthCheckupTimeline.css";
import ConditionalRender from "../ConditionalRender/ConditionalRender";
import Button from "../Button/Button";
import empty from "../../assets/images/fallback.svg";

export const HealthCheckupTimeline = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const parentDivRef = useRef();
  const slidesRef = useRef([]);

  useEffect(() => {
    if (props.selectedSlide) {
      setCurrentSlide(props.selectedSlide);
    }
    // eslint-disable-next-line
  }, [props.selectedSlide]);

  useEffect(() => {
    if (props.filter.length <= currentSlide) {
      setCurrentSlide(props.filter.length - 1);
    }
    // eslint-disable-next-line
  }, [currentSlide, props.filter]);

  return (
    <>
      {props.filter.length !== 0 && (
        <SliderControl
          setSelectedSlide={setCurrentSlide}
          currentSlide={currentSlide}
          filter={props.filter}
          cardType={props.cardType}
        />
      )}
      <div
        ref={parentDivRef}
        className={`inherit-parent-width carousel-remaining-height hide-scrollbar`}
      >
        {props.filter &&
          (props.filter.includes("active") ||
            props.filter.includes("completed") ||
            props.filter.includes("expired")) && (
            <Carousel
              className="over-flow-y-scroll inherit-parent-height inherit-parent-width"
              showStatus={false}
              showArrows={false}
              showThumbs={false}
              showIndicators={false}
              selectedItem={currentSlide}
              onChange={(index) => {
                setCurrentSlide(index);
              }}
              transitionTime={0}
              swipeScrollTolerance={100}
              preventMovementUntilSwipeScrollTolerance={true}
              swipeable={true}
              stopOnHover={false}
            >
              {props.filter && props.filter.includes("active") && (
                <div
                  ref={(element) => (slidesRef.current[0] = element)}
                  className="padding-horizontal-larger inherit-parent-height inherit-parent-width"
                >
                  {props.cardType === "clinic" ? (
                    <>
                      <ConditionalRender
                        condition={props.checkupsData.some(
                          (data) =>
                            data.status === "APPOINTED" &&
                            data.expiringAt > +new Date()
                        )}
                      >
                        {props.checkupsData
                          .filter(
                            (data) =>
                              data.status === "APPOINTED" &&
                              data.expiringAt > +new Date()
                          )
                          .map((data, index) => (
                            <div key={index}>
                              {/* for clinic there is no need for requested */}
                              <Card
                                removeBottomLine={
                                  props.checkupsData.filter(
                                    (data) =>
                                      data.status === "APPOINTED" &&
                                      data.expiringAt > +new Date()
                                  ).length -
                                    1 ===
                                  index
                                }
                                connection={props.connection}
                                data-cy={`${data.requestId}-card`}
                                cardType={props.cardType}
                                cardData={data}
                                cardOnClick={() => props.cardOnClick(data)}
                              />
                            </div>
                          ))}
                      </ConditionalRender>
                      <ConditionalRender
                        condition={
                          !props.checkupsData.some(
                            (data) =>
                              data.status === "APPOINTED" &&
                              data.expiringAt > +new Date()
                          )
                        }
                      >
                        <NoRequestAvailable
                          cardType={props.cardType}
                          status="active"
                          createNewRequestOnClick={
                            props.createNewRequestOnClick
                          }
                        />
                      </ConditionalRender>
                    </>
                  ) : (
                    <>
                      <ConditionalRender
                        condition={props.checkupsData.some(
                          (data) =>
                            data.status !== "COMPLETED" &&
                            data.expiringAt > +new Date()
                        )}
                      >
                        {props.checkupsData
                          .filter(
                            (data) =>
                              data.status !== "COMPLETED" &&
                              data.expiringAt > +new Date()
                          )
                          .map((data, index) => (
                            <div key={index}>
                              <>
                                <Card
                                  removeBottomLine={
                                    props.checkupsData.filter(
                                      (data) =>
                                        data.status !== "COMPLETED" &&
                                        data.expiringAt > +new Date()
                                    ).length -
                                      1 ===
                                    index
                                  }
                                  connection={props.connection}
                                  data-cy={`${data.requestId}-card`}
                                  cardType={props.cardType}
                                  cardData={data}
                                  cardOnClick={() => props.cardOnClick(data)}
                                />
                              </>
                            </div>
                          ))}
                      </ConditionalRender>
                      <ConditionalRender
                        condition={
                          !props.checkupsData.some(
                            (data) =>
                              data.status !== "COMPLETED" &&
                              data.expiringAt > +new Date()
                          )
                        }
                      >
                        <NoRequestAvailable
                          cardType={props.cardType}
                          status="active"
                          createNewRequestOnClick={
                            props.createNewRequestOnClick
                          }
                        />
                      </ConditionalRender>
                    </>
                  )}
                </div>
              )}

              {props.filter && props.filter.includes("completed") && (
                <div
                  ref={(element) => (slidesRef.current[1] = element)}
                  className="padding-horizontal-larger inherit-parent-height inherit-parent-width"
                >
                  <ConditionalRender
                    condition={props.checkupsData.some(
                      (data) => data.status === "COMPLETED"
                    )}
                  >
                    {props.checkupsData
                      .filter((data) => data.status === "COMPLETED")
                      .map((data, index) => (
                        <div key={index}>
                          <Card
                            removeBottomLine={
                              props.checkupsData.filter(
                                (data) => data.status === "COMPLETED"
                              ).length -
                                1 ===
                              index
                            }
                            connection={props.connection}
                            data-cy={`${data.requestId}-card`}
                            cardType={props.cardType}
                            cardData={data}
                            cardOnClick={() => props.cardOnClick(data)}
                          />
                        </div>
                      ))}
                  </ConditionalRender>
                  <ConditionalRender
                    condition={
                      !props.checkupsData.some(
                        (data) => data.status === "COMPLETED"
                      )
                    }
                  >
                    <NoRequestAvailable
                      cardType={props.cardType}
                      status="completed"
                      createNewRequestOnClick={props.createNewRequestOnClick}
                    />
                  </ConditionalRender>
                </div>
              )}

              {props.filter && props.filter.includes("expired") && (
                <div
                  ref={(element) => (slidesRef.current[2] = element)}
                  className="padding-horizontal-larger inherit-parent-height inherit-parent-width"
                >
                  <ConditionalRender
                    condition={props.checkupsData.some(
                      (data) =>
                        data.status !== "COMPLETED" &&
                        !(data.expiringAt > +new Date())
                    )}
                  >
                    {props.checkupsData
                      .filter(
                        (data) =>
                          data.status !== "COMPLETED" &&
                          !(data.expiringAt > +new Date())
                      )
                      .map((data, index) => (
                        <div key={index}>
                          <Card
                            removeArrowIcon={true}
                            removeBottomLine={
                              props.checkupsData.filter(
                                (data) =>
                                  data.status !== "COMPLETED" &&
                                  !(data.expiringAt > +new Date())
                              ).length -
                                1 ===
                              index
                            }
                            connection={props.connection}
                            data-cy={`${data.requestId}-card`}
                            cardType={props.cardType}
                            cardData={data}
                            // cardOnClick={() => props.cardOnClick(data)}
                          />
                        </div>
                      ))}
                  </ConditionalRender>
                  <ConditionalRender
                    condition={
                      !props.checkupsData.some(
                        (data) =>
                          data.status !== "COMPLETED" &&
                          !(data.expiringAt > +new Date())
                      )
                    }
                  >
                    <NoRequestAvailable
                      cardType={props.cardType}
                      status="expired"
                      createNewRequestOnClick={props.createNewRequestOnClick}
                    />
                  </ConditionalRender>
                </div>
              )}
            </Carousel>
          )}

        {props.filter.length === 0 && (
          <div className="inherit-parent-width inherit-parent-height">
            {props.checkupsData.map((data, index) => (
              <div key={index}>
                <Card
                  removeArrowIcon={
                    !(data.expiringAt > +new Date()) &&
                    data.status !== "COMPLETED"
                  }
                  connection={props.connection}
                  removeBottomLine={props.checkupsData.length - 1 === index}
                  data-cy={`${data.requestId}-card`}
                  cardType={props.cardType}
                  cardData={data}
                  cardOnClick={() => props.cardOnClick(data)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

const SliderControl = (props) => {
  return (
    <div
      className={`flex-justify-content-space-around height-30px background-color-white flex-align-items-end font-family-gilroy-light font-size-small font-color-tertiary border-bottom-1px-e5e5e5 background-white position-sticky top-0 z-index-1`}
    >
      {props.filter && props.filter.includes("active") && (
        <div>
          <div
            data-cy="active-slider-control"
            onClick={() => {
              props.setSelectedSlide(0);
            }}
            className={`${
              props.currentSlide === 0 ? "font-color-black" : ""
            } padding-horizontal-larger cursor-pointer`}
          >
            ACTIVE
          </div>
          <div
            className={`${
              props.currentSlide === 0 ? "background-color-primary" : ""
            }  height-1px inherit-parent-width margin-top-small`}
          />
        </div>
      )}

      {props.filter && props.filter.includes("completed") && (
        <div>
          <div
            data-cy="completed-slider-control"
            onClick={() => {
              props.setSelectedSlide(1);
            }}
            className={`${
              props.currentSlide === 1 ? "font-color-black" : ""
            } padding-horizontal-larger  cursor-pointer`}
          >
            COMPLETED
          </div>
          <div
            className={`${
              props.currentSlide === 1 ? "background-color-primary" : ""
            }  height-1px inherit-parent-width margin-top-small`}
          />
        </div>
      )}

      {props.filter && props.filter.includes("expired") && (
        <div>
          <div
            data-cy="expired-slider-control"
            onClick={() => {
              props.setSelectedSlide(2);
            }}
            className={`${
              props.currentSlide === 2 ? " font-color-black" : ""
            } padding-horizontal-larger cursor-pointer`}
          >
            EXPIRED
          </div>

          <div
            className={`${
              props.currentSlide === 2 ? "background-color-primary" : ""
            }  height-1px inherit-parent-width margin-top-small`}
          />
        </div>
      )}
    </div>
  );
};

const Card = (props) => {
  const RemainingDays = (timestamp) => {
    const day = Math.round((timestamp - +new Date()) / 86400000);
    if (props.cardData.status === "COMPLETED") {
      return null;
    } else if (day <= 0) {
      return null;
    } else {
      return `${day} DAY${day !== 1 && "S"} REMAINING`;
    }
  };

  return (
    <>
      {props.cardType === "corporate" && (
        <div
          data-cy={props["data-cy"]}
          className={`${
            props.removeBottomLine ? "padding-bottom-larger" : ""
          } inherit-parent-width padding-top-large cursor-pointer`}
          onClick={props.cardOnClick}
        >
          <div>
            <div className="inherit-parent-width flex-justify-content-space-between">
              <div className=" text-capitalize">
                {props.cardData.name
                  ? props.cardData.name
                  : props.cardData.patientPhoneNumber}
              </div>
              {!props.removeArrowIcon && (
                <div>
                  <img
                    src={arrow}
                    alt="arrow"
                    className=" height-8px transform-rotate-270deg margin-top-small "
                  />
                </div>
              )}
            </div>
            <div className=" text-align-left font-size-small margin-top-small text-capitalize">
              {props.cardData.description}
            </div>
          </div>

          <div className="inherit-parent-width flex-justify-content-space-between margin-top-default">
            <div className=" flex-center-children-vertically margin-top-small">
              <RoundedProfilePicture
                size="smaller"
                src={
                  props.connection?.clinicBrandLogo &&
                  props.connection?.clinicBrandLogo[
                    props.cardData.clinicBrandId
                  ]
                }
              />
              <div className="font-size-small font-family-gilroy-regular margin-left-default">
                {props.cardData.clinicName
                  ? props.cardData.clinicName
                  : "No appointment yet"}
              </div>
            </div>

            <div className="  flex-direction-column flex-center-children-horizontally">
              <div className="font-size-small text-align-right font-family-gilroy-regular">
                {RemainingDays(props.cardData.expiringAt) === null &&
                props.cardData.status !== "COMPLETED"
                  ? "EXPIRED"
                  : props.cardData.status === "COMPLETED" &&
                    props.cardData.documentId
                  ? "REPORT READY"
                  : props.cardData.status}
              </div>

              <div className=" font-size-smaller text-align-right font-family-gilroy-light margin-top-small">
                {RemainingDays(props.cardData.expiringAt)}
              </div>
            </div>
          </div>
          {!props.removeBottomLine && (
            <div className=" background-color-grey height-1px margin-top-large" />
          )}
        </div>
      )}

      {props.cardType === "clinic" && (
        <div
          data-cy={props["data-cy"]}
          className={`${
            props.removeBottomLine ? "padding-bottom-larger" : ""
          } inherit-parent-width padding-top-large cursor-pointer`}
          onClick={props.cardOnClick}
        >
          <div>
            <div className="inherit-parent-width flex-justify-content-space-between">
              <div className="text-capitalize">{props.cardData.name}</div>
              <div>
                <img
                  src={arrow}
                  alt="arrow"
                  className=" height-8px transform-rotate-270deg margin-top-small "
                />
              </div>
            </div>
            <div className=" text-align-left text-capitalize font-size-small margin-top-small">
              {props.cardData.description}
            </div>
          </div>

          <div className="inherit-parent-width flex-justify-content-space-between margin-top-default">
            <div className=" flex-center-children-vertically margin-top-small">
              <RoundedProfilePicture
                size="smaller"
                src={
                  props.connection?.corporateBrands &&
                  props.connection.corporateBrands[
                    props.cardData.corporateBrandId
                  ]?.downloadURL
                }
              />

              <div className="font-size-small text-capitalize font-family-gilroy-regular margin-left-default">
                {props.cardData.corporateName}
              </div>
            </div>

            <div className="flex-direction-column flex-center-children-horizontally">
              <div className="font-size-small text-align-right font-family-gilroy-regular">
                {RemainingDays(props.cardData.expiringAt) === null &&
                props.cardData.status !== "COMPLETED"
                  ? "EXPIRED"
                  : props.cardData.status === "COMPLETED" &&
                    props.cardData.documentId
                  ? "REPORT UPLOADED"
                  : props.cardData.status}
              </div>

              <div className="font-size-smaller text-align-right font-family-gilroy-light margin-top-small">
                {RemainingDays(props.cardData.expiringAt)}
              </div>
            </div>
          </div>
          {!props.removeBottomLine && (
            <div className=" background-color-grey height-1px margin-top-large" />
          )}
        </div>
      )}

      {props.cardType === "employee" && (
        <div
          data-cy={props["data-cy"]}
          className={`${
            props.removeBottomLine ? "padding-bottom-larger" : ""
          } inherit-parent-width padding-top-large cursor-pointer`}
          onClick={props.cardOnClick}
        >
          <div className="inherit-parent-width flex-justify-content-space-between">
            <div
              className={`${
                props.cardData.status === "COMPLETED" ||
                !(props.cardData.expiringAt > +new Date()) ||
                (props.cardData.status === "COMPLETED" &&
                  !(props.cardData.expiringAt > +new Date()))
                  ? "completed-text-color"
                  : ""
              } text-capitalize`}
            >
              {props.cardData.description}
            </div>
            {!props.removeArrowIcon && (
              <div>
                <img
                  src={arrow}
                  alt="arrow"
                  className=" height-8px transform-rotate-270deg margin-top-small"
                />
              </div>
            )}
          </div>
          {/* completed-text-color */}
          <div className="inherit-parent-width flex-justify-content-space-between margin-top-default">
            <div className=" flex-center-children-vertically margin-top-small">
              <RoundedProfilePicture
                size="smaller"
                src={
                  props.connection.corporateBrandLogo &&
                  props.connection.corporateBrandLogo[
                    props.cardData.corporateBrandId
                  ]?.downloadUrl
                }
              />

              <div
                className={`${
                  props.cardData.status === "COMPLETED" ||
                  !(props.cardData.expiringAt > +new Date()) ||
                  (props.cardData.status === "COMPLETED" &&
                    !(props.cardData.expiringAt > +new Date()))
                    ? "completed-text-color"
                    : ""
                } font-size-small font-family-gilroy-regular text-capitalize margin-left-small`}
              >
                {props.cardData.corporateName}
              </div>
            </div>

            <div className="  flex-direction-column flex-center-children-horizontally">
              <div className="font-size-small text-align-right font-family-gilroy-regular">
                {RemainingDays(props.cardData.expiringAt) === null &&
                props.cardData.status !== "COMPLETED"
                  ? "EXPIRED"
                  : props.cardData.status === "COMPLETED" &&
                    props.cardData.documentId &&
                    props.cardData.shareReportWithUser
                  ? "REPORT READY"
                  : props.cardData.status}
              </div>

              <div className=" font-size-smaller text-align-right font-family-gilroy-light margin-top-small">
                {RemainingDays(props.cardData.expiringAt)}
              </div>
            </div>
          </div>
          {!props.removeBottomLine && (
            <div className=" background-color-grey height-1px margin-top-large" />
          )}
        </div>
      )}
    </>
  );
};

const NoRequestAvailable = (props) => {
  return (
    <div className="inherit-parent-height flex-center-children-horizontally flex-direction-column  inherit-parent-width">
      <div className="padding-top-larger">
        <img height={148} width={120} src={empty} alt="empty" />
      </div>

      <div className=" font-family-gilroy-medium padding-top-larger">
        {props.cardType === "clinic"
          ? "No appointment available"
          : "No requests available."}
      </div>

      <div className=" text-align-center padding-top-larger">
        {props.cardType === "corporate"
          ? props.status === "active"
            ? "Please create a new request to send to your employee(s) for their health checkup."
            : props.status === "completed"
            ? "All completed requests will appear here."
            : props.status === "expired"
            ? "All expired requests will appear here."
            : ""
          : ""}
        {props.cardType === "clinic"
          ? props.status === "active"
            ? "Once a patient books an appointment with your clinic, the checkup requests appear here."
            : props.status === "completed"
            ? "All completed requests will appear here."
            : props.status === "expired"
            ? "All expired requests will appear here."
            : ""
          : ""}
        {props.cardType === "employee"
          ? props.status === "active"
            ? "The checkup requests appear here once your employer sends a request"
            : props.status === "completed"
            ? "All completed requests will appear here."
            : props.status === "expired"
            ? "All expired requests will appear here."
            : ""
          : ""}
      </div>
      {props.cardType === "corporate" &&
        props.status !== "completed" &&
        props.status !== "expired" && (
          <Button
            data-cy="create-new-request-button"
            type="button"
            className="margin-top-larger"
            text="Create new request"
            onClick={props.createNewRequestOnClick}
            boxShadow={false}
          />
        )}
    </div>
  );
};
