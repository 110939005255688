import {
  query,
  where,
  collection,
  onSnapshot,
  getDocs,
  collectionGroup,
  updateDoc,
  doc,
  orderBy
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { statusActions } from "../redux/sagas/statusSaga";
import { profileActions } from "../redux/sagas/profileSaga";
import { database } from "./firebase";
import { corporateActions } from "../redux/sagas/corporateSaga";

export async function editProfile(data, profileId) {
  const rootRef = doc(database, "patient-demographics", profileId);
  await updateDoc(rootRef, {
    ...(data.userName ? { name: data.userName } : {})
  });
}

export function useProfileListener(props) {
  const [profileListener, setProfileListener] = useState({
    listener: null
  });

  const subscribeToProfile = (phoneNumber) => {
    if (phoneNumber) {
      const profileQuery = query(
        collection(database, "patient-demographics"),
        where("phoneNumber", "==", phoneNumber)
      );

      return onSnapshot(
        profileQuery,
        (dataSnapshot) => {
          let profiles = {};
          dataSnapshot.forEach((doc) => {
            profiles[doc.id] = {
              ...doc.data()
            };
          });
          profileActions.setAccountProfiles(profiles);
        },
        (error) => {
          statusActions.setErrorStatus(error);
        }
      );
    } else {
      return;
    }
  };
  useEffect(() => {
    if (
      props.isAuthed === true &&
      typeof props.phoneNumber === "string" &&
      typeof props.uid === "string" &&
      profileListener.listener === null
    ) {
      setProfileListener({
        listener: subscribeToProfile(props.phoneNumber)
      });
    } else if (
      props.isAuthed === false &&
      typeof props.phoneNumber !== "string" &&
      typeof props.uid !== "string" &&
      typeof profileListener.listener === "function"
    ) {
      profileListener.listener();
      setProfileListener({
        listener: null
      });
    }
    // eslint-disable-next-line
  }, [props.isAuthed, props.phoneNumber, props.uid]);
}

export function useCorporateCheckupsListener(props) {
  const [checkupsListener, setCheckupsListener] = useState({
    listener: null
  });

  const subscribeToProfile = (phoneNumber) => {
    if (phoneNumber) {
      const profileQuery = query(
        collection(database, "corporateCheckups"),
        where("patientPhoneNumber", "==", phoneNumber),
        orderBy("requestedAt", "desc")
      );

      return onSnapshot(
        profileQuery,
        (dataSnapshot) => {
          let corporateBrandId = [];
          let clinicBrandId = [];
          let checkups = [];
          dataSnapshot.forEach((doc) => {
            checkups.push({ ...doc.data(), requestId: doc.id });
            if (doc.data().corporateBrandId) {
              corporateBrandId.push(doc.data().corporateBrandId);
            }
            if (doc.data().clinicBrandId) {
              clinicBrandId.push(doc.data().clinicBrandId);
            }
            if (doc.data().documentId) {
              corporateActions.getReportDownloadAbleURL({
                documentId: doc.data().documentId,
                url: doc.data().url,
                clinicId: doc.data().clinicId,
                patientId: doc.data().patientId
              });
            }
          });
          corporateActions.setCorporateLogo(corporateBrandId);
          corporateActions.corporateCheckups(checkups);
        },
        (error) => {
          statusActions.setErrorStatus(error);
        }
      );
    }
  };
  useEffect(() => {
    if (
      props.isAuthed === true &&
      typeof props.phoneNumber === "string" &&
      checkupsListener.listener === null
    ) {
      setCheckupsListener({
        listener: subscribeToProfile(props.phoneNumber)
      });
    } else if (
      props.isAuthed === false &&
      typeof checkupsListener.listener === "function"
    ) {
      checkupsListener.listener();
      setCheckupsListener({
        listener: null
      });
    }
    // eslint-disable-next-line
  }, [props.phoneNumber, props.isAuthed]);
}

export async function getPartneredClinics(corporateBrandId) {
  const corporatePackages = (
    await getDocs(
      query(
        collection(database, "corporatePackages"),
        where("corporateBrandId", "==", corporateBrandId),
        where("accepted", "==", true)
      )
    )
  ).docs;

  let partneredClinicBrands = [];
  if (corporatePackages.length === 0) {
    return [];
  }

  corporatePackages.forEach((doc) => {
    partneredClinicBrands.push(doc.data().clinicBrandId);
  });

  const partneredClinics = await getDocs(
    query(
      collectionGroup(database, "clinicsPublic"),
      where("brandId", "in", partneredClinicBrands),
      where("verified", "==", true)
    )
  );

  let clinics = {};
  partneredClinics.forEach((doc) => {
    clinics[doc.data().clinicId ? doc.data().clinicId : doc.data().uid] =
      doc.data();
  });

  return clinics;
}

export async function setAppointmentForHealthCheckup(healthRequestId, data) {
  await updateDoc(doc(database, "corporateCheckups", healthRequestId), {
    ...data,
    appointmentFixedAt: +new Date()
  });
}

export async function updateCheckupName(healthRequestId, data) {
  await updateDoc(doc(database, "corporateCheckups", healthRequestId), data);
}
