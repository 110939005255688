import { put, takeEvery, all } from "redux-saga/effects";
import { checkAccountAvailability } from "../../services/api";
import {
  otpValidate,
  logout,
  otpRequest,
  deleteUserAccount,
  signInWithAuthCredentials,
  signUpWithAuthCredentials
} from "../../services/authentication";
import { statusActions } from "./statusSaga";
import store from "../store/store";
import { throwError } from "../../services/error";
import { isOnline } from "../../utils/validators";

export const actionTypes = {
  SEND_OTP: "SEND_OTP",
  VERIFY_OTP: "VERIFY_OTP",
  SIGNUP: "SIGNUP",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  ADD_ACCESS_TOKEN: "ADD_ACCESS_TOKEN",
  CLEAR_CREDENTIAL: "CLEAR_CREDENTIAL",
  DELETE_ACCOUNT: "DELETE_ACCOUNT"
};

export const authActions = {
  sendOtp: (phoneNumber, type) => {
    store.dispatch({
      type: actionTypes.SEND_OTP,
      payload: {
        phoneNumber: phoneNumber,
        type: type
      }
    });
  },

  verifyOtp: (loginOtp, phoneNumber, displayName, type) => {
    store.dispatch({
      type: actionTypes.VERIFY_OTP,
      payload: {
        loginOtp: loginOtp,
        phoneNumber: phoneNumber,
        displayName: displayName,
        type: type
      }
    });
  },

  signUp: (phoneNumber, displayName) => {
    store.dispatch({
      type: actionTypes.SIGNUP,
      payload: {
        phoneNumber: phoneNumber,
        displayName: displayName
      }
    });
  },

  login: (loginOtp) => {
    store.dispatch({
      type: actionTypes.LOGIN,
      payload: {
        loginOtp: loginOtp
      }
    });
  },

  clearCredentials: () => {
    store.dispatch({
      type: actionTypes.CLEAR_CREDENTIAL
    });
  },

  logout: () => {
    store.dispatch({
      type: actionTypes.LOGOUT
    });
  },

  deleteAccount: () => {
    store.dispatch({
      type: "DELETE_ACCOUNT"
    });
  },

  addAccessToken: (accessToken) => {
    store.dispatch({
      type: actionTypes.ADD_ACCESS_TOKEN,
      payload: {
        accessToken: accessToken
      }
    });
  }
};

function* signUpWorker(action) {
  try {
    yield setAuthLoading(true);
    const response = yield signUpWithAuthCredentials(
      store.getState().auth.credentials.authCredential,
      action.payload.displayName,
      action.payload.phoneNumber
    );
    yield put({
      type: "SET_AUTH_INFO",
      payload: {
        accessToken: response.user.accessToken,
        uid: response.user.uid,
        displayName: action.payload.displayName,
        phoneNumber: response.user.phoneNumber
      }
    });
    yield setAuthLoading(false);
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* loginWorker() {
  try {
    yield setAuthLoading(true);
    const uid = store.getState().auth.data.uid;
    const profile = store.getState().profile.data;
    if (uid === null && profile === null) {
      const response = yield signInWithAuthCredentials(
        store.getState().auth.credentials.authCredential
      );
      yield put({
        type: "SET_AUTH_INFO",
        payload: {
          accessToken: response.user.accessToken,
          uid: response.user.uid,
          phoneNumber: response.user.phoneNumber
        }
      });
    } else if (uid !== null && profile !== null) {
      throw throwError("custom", "User already logged in");
    }
    yield setAuthLoading(false);
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* logoutWorker() {
  try {
    yield setAuthLoading(true);
    yield logout();
    yield put({
      type: "RESET"
    });
    yield setAuthLoading(false);
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* sendOtpWorker(action) {
  try {
    if (isOnline()) {
      yield setAuthLoading(true);

      if (action.payload.type === "signUp") {
        const response = yield checkAccountAvailability(
          action.payload.phoneNumber
        );
        if (
          response.data.phoneNumberExists === true &&
          response.data.profileExists === true
        ) {
          throw throwError("custom", "User already registered. Please login");
        }
      } else if (action.payload.type === "login") {
        const response = yield checkAccountAvailability(
          action.payload.phoneNumber
        );
        if (response.data.profileExists !== true) {
          throw throwError("custom", "User does not exist");
        }
      }

      const verificationId = yield otpRequest(action.payload.phoneNumber);
      yield put({
        type: "SET_AUTH_VERIFICATION_ID",
        payload: {
          verificationId: verificationId
        }
      });

      yield setAuthLoading(false);
    }
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* verifyOtpWorker(action) {
  try {
    if (isOnline()) {
      yield setAuthLoading(true);
      const authCredential = yield otpValidate(
        action.payload.loginOtp,
        store.getState().auth.credentials.verificationId
      );

      yield put({
        type: "SET_AUTH_CREDENTIALS",
        payload: {
          authCredential: authCredential
        }
      });

      if (action.payload.type === "signUp") {
        authActions.signUp(
          action.payload.clinicName,
          action.payload.phoneNumber,
          action.payload.coordinatorName
        );
      } else {
        authActions.login();
      }
      yield setAuthLoading(false);
    }
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* clearCredentialWorker() {
  yield put({
    type: "CLEAR_AUTH_CREDENTIALS"
  });
}

function* deleteAccountWorker() {
  try {
    yield setAuthLoading(true);
    yield deleteUserAccount();
    yield put({
      type: "RESET"
    });
    yield setAuthLoading(false);
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* addAccessTokenWorker(action) {
  try {
    yield put({
      type: "SET_ACCESS_TOKEN",
      payload: {
        accessToken: action.payload.accessToken
      }
    });
  } catch (error) {
    yield statusActions.setErrorStatus(error);
  }
}

export function* authWatcher() {
  yield all([
    takeEvery("SEND_OTP", sendOtpWorker),
    takeEvery("VERIFY_OTP", verifyOtpWorker),
    takeEvery("LOGIN", loginWorker),
    takeEvery("LOGOUT", logoutWorker),
    takeEvery("SIGNUP", signUpWorker),
    takeEvery("ADD_ACCESS_TOKEN", addAccessTokenWorker),
    takeEvery("CLEAR_CREDENTIAL", clearCredentialWorker),
    takeEvery("DELETE_ACCOUNT", deleteAccountWorker)
  ]);
}

function* setAuthLoading(bool) {
  yield put({
    type: "SET_AUTH_LOADING",
    payload: {
      loading: bool
    }
  });
}
