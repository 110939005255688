import React, { useState, useEffect } from "react";
import "./Modal.css";
import PropTypes from "prop-types";

function Modal(props) {
  const [show, setShow] = useState();

  useEffect(() => {
    setShow(props.show ? props.show : false);
  }, [props.show]);

  const getBackgroundClassNames = (customClassNames) => {
    return `z-index-101 background-color-black-with-opacity-light position-fixed top-0 bottom-0 right-0 left-0 inherit-parent-height inherit-parent-width ${
      customClassNames ? customClassNames : ``
    }`;
  };

  return (
    <>
      {show === true ? (
        <>
          <div
            data-cy="modalBackground"
            className={getBackgroundClassNames(props.modalBackgroundClassName)}
            onClick={() => {
              if (props.canIgnore === true) {
                setShow(false);
                if (typeof props.onClose === "function") {
                  props.onClose();
                }
              }
            }}
          ></div>
          <div
            className={`z-index-101 ${
              props.modalBodyClassName ? props.modalBodyClassName : ``
            } ${
              props.borderRadius ? props.borderRadius : "border-radius-default"
            }
            ${props.position ? props.position : "position-fixed-center-self"} ${
              props.height ? props.height : "minimum-height-250px"
            }
            ${props.boxShadow ? props.boxShadow : "box-shadow-default"} ${
              props.background ? props.background : "background-white"
            }
            ${props.minWidth ? props.minWidth : "minimum-width-250px"} ${
              props.width ? props.width : "width-auto"
            }
            ${props.maxWidth ? props.maxWidth : "max-width"} ${
              props.maxHeight ? props.maxHeight : "max-height"
            }`}
          >
            {props.children ? props.children : <div></div>}
          </div>
        </>
      ) : null}
    </>
  );
}

Modal.propTypes = {
  /**
   * which used to handle the modal
   */
  show: PropTypes.bool,
  /**
   * modal background overlay className
   */
  modalBackgroundClassName: PropTypes.string,
  /**
   * which is used close the modal while clicking outside
   */
  canIgnore: PropTypes.bool,
  /**
   * callback function which is called while closing the modal ,works only `canIgnore=true`
   */
  onClose: PropTypes.func,
  /**
   * modal content parent element class name
   */
  modalBodyClassName: PropTypes.string,
  /**
   * modal content parent element border radius class name
   */
  borderRadius: PropTypes.string,
  /**
   * position of the modal body `class name`
   */
  position: PropTypes.string,
  /**
   * min height of the modal body `className`
   */
  height: PropTypes.string,
  /**
   * modal content box shadow class name
   */
  boxShadow: PropTypes.string,
  /**
   * modal content background class name
   */
  background: PropTypes.string,
  /**
   * modal content min width class name
   */
  minWidth: PropTypes.string,
  /**
   * modal content width class name
   */
  width: PropTypes.string,
  /**
   * modal content max width class name
   */
  maxWidth: PropTypes.string,
  /**
   * modal content max height class name
   */
  maxHeight: PropTypes.string
};

Modal.defaultProps = {
  show: false,
  modalBackgroundClassName: "",
  canIgnore: false,
  onClose: undefined,
  modalBodyClassName: "",
  borderRadius: "border-radius-default",
  position: "position-fixed-center-self",
  height: "minimum-height-250px",
  boxShadow: "box-shadow-default",
  minWidth: "minimum-width-250px",
  width: "width-auto",
  maxWidth: "max-width",
  maxHeight: "max-height"
};

export default Modal;
