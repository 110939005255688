import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { corporateActions } from "../../redux/sagas/corporateSaga";
import Header from "../../front-end-global-components/components/Header/Header";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import "./SelectedRequest.css";
import Button from "../../front-end-global-components/components/Button/Button";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import ProfileCards from "../../components/ProfileCards/ProfileCards";
import NativeSelect from "../../front-end-global-components/components/NativeSelect/NativeSelect";
import backIcon from "../../front-end-global-components/assets/Back.svg";
import ProgressTrack from "../../front-end-global-components/components/ProgressTrack/ProgressTrack";
// import { FilterIcon } from "../../front-end-global-components/assets/assets";
import { statusActions } from "../../redux/sagas/statusSaga";
import { isValidArray, isValidObject } from "../../utils/validators";

function SelectedRequest(props) {
  const [showSelectClinicModal, setShowSelectClinicModal] = useState(false);
  const [showClinicFilterModal, setShowClinicFilterModal] = useState(false);
  const [selectCheckupDateModal, setSelectCheckupDateModal] = useState(false);
  const [checkupDate, setCheckupDate] = useState(null);
  const [filterState, setFilterState] = useState({ 0: "" });
  const [filterCity, setFilterCity] = useState({ 0: "" });
  const [filterSelectedData, setFilterSelectedData] = useState({
    state: null,
    city: null
  });

  const [filterOnchangeSelectedData, setFilterOnchangeSelectedData] = useState({
    state: null,
    city: null
  });

  const [selectedRequest, setSelectedRequest] = useState(null);
  useEffect(() => {
    if (
      typeof props.corporate.selectedRequest !== "string" &&
      !isValidArray(props.corporate.checkups)
    ) {
      props.navigate("/");
    }
    if (typeof props.corporate.selectedRequest === "string") {
      setSelectedRequest(
        props.corporate.checkups?.find(
          (data) => data.requestId === props.corporate.selectedRequest
        )
      );
    }
    // eslint-disable-next-line
  }, [props.corporate.selectedRequest, props.corporate.checkups]);

  return (
    <>
      <div className="inherit-parent-height flex-place-children-page-center ">
        <div className="inherit-parent-height inherit-parent-width large-screen-bg-color large-screen-height large-screen-width large-screen-border ">
          <Header
            removeResponsive={true}
            title="Checkup status"
            backButtonOnClick={() => {
              props.clearSelectedRequest();
              props.navigate("/");
            }}
            hideBackButton
            supportIconOnClick={() => {}}
          />
          <div className=" remaining-body-height inherit-parent-width padding-top-larger font-color-secondary overflow-y-scroll hide-scrollbar">
            {props.corporate.loading && !selectedRequest ? (
              <SelectedRequestSuspense />
            ) : (
              isValidObject(selectedRequest) && (
                <>
                  <div className=" flex-justify-content-space-between padding-horizontal-larger ">
                    <div className="flex-center-children-vertically margin-top-small">
                      <div>
                        <RoundedProfilePicture
                          src={
                            props.corporate?.corporateBrandLogo &&
                            props.corporate.corporateBrandLogo[
                              selectedRequest?.corporateBrandId
                            ]?.downloadUrl
                          }
                          size="small"
                        />
                      </div>
                      <div
                        data-cy="status"
                        className="font-family-gilroy-regular margin-left-default text-capitalize"
                      >
                        {selectedRequest?.corporateName}
                      </div>
                    </div>

                    <div className="flex-direction-column flex-center-children-horizontally">
                      <div
                        data-cy="status"
                        className="font-size-small text-align-right font-family-gilroy-regular"
                      >
                        {selectedRequest?.status}
                      </div>
                      {selectedRequest?.status !== "COMPLETED" && (
                        <div className=" font-size-smaller text-align-right font-family-gilroy-light margin-top-small">
                          {RemainingDays(selectedRequest?.expiringAt)}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="margin-top-medium padding-horizontal-larger ">
                    <div className="font-size-smaller font-family-gilroy-regular">
                      DESCRIPTION
                    </div>
                    <div className="font-size-large font-family-gilroy-regular margin-top-small text-capitalize">
                      {selectedRequest?.description}
                    </div>
                  </div>

                  <div className=" background-color-grey height-2px margin-top-large " />
                  <div className="padding-horizontal-larger ">
                    <ProgressTrack
                      data={selectedRequest}
                      reScheduleOnclick={() => {
                        setShowSelectClinicModal(true);
                      }}
                      bookAppointmentOnclick={() => {
                        setShowSelectClinicModal(true);
                      }}
                      isDownloadUrlPresent={
                        props.corporate?.downloadableURLs &&
                        Object.keys(props.corporate?.downloadableURLs).includes(
                          selectedRequest?.documentId
                        )
                      }
                      pdfOnclick={() => {
                        if (
                          props.corporate?.downloadableURLs[
                            selectedRequest?.documentId
                          ]
                        ) {
                          props.navigate(
                            `/documents/${selectedRequest?.documentId}`
                          );
                        }
                      }}
                      connection={props.corporate}
                      userType="employee"
                      options={
                        selectedRequest?.shareReportWithUser
                          ? [
                              "requestReceived",
                              "appointment",
                              "healthCheckup",
                              "report"
                            ]
                          : ["requestReceived", "appointment", "healthCheckup"]
                      }
                    />
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </div>
      {/* SELECT CLINIC MODAL */}
      <Modal
        show={
          showSelectClinicModal &&
          selectedRequest?.status !== "COMPLETED" &&
          !showClinicFilterModal &&
          !selectCheckupDateModal
        }
        canIgnore={true}
        onClose={() => {
          setShowSelectClinicModal(!showSelectClinicModal);
        }}
        position={
          props.windowInnerWidth > 576
            ? ""
            : "position-fixed bottom-0 left-0 right-0"
        }
        width="inherit-parent-width"
        maxWidth={props.windowInnerWidth < 576 ? "" : "max-width-500px"}
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-fit-to-content"
      >
        <div
          className={`background-white padding-larger ${
            props.windowInnerWidth < 576
              ? "border-radius-top-default"
              : "border-radius-default"
          } box-shadow-default font-family-gilroy-regular font-color-secondary max-height-650px overflow-auto `}
        >
          <div className=" font-family-gilroy-medium text-align-center">
            Select clinic
          </div>
          <div className=" position-absolute top-10px right-25px height-10px">
            <Button
              boxShadow={false}
              buttonCategory="floating"
              removeResponsive={true}
              positionFixed={false}
              // src={FilterIcon}
              onClick={() => {
                setShowClinicFilterModal(true);
                let state = { 0: "" };
                props.corporate.availableClinics &&
                  Object.values(props.corporate.availableClinics).forEach(
                    (data, index) => {
                      state = {
                        ...state,
                        [data.state]: data.state
                      };
                    }
                  );
                setFilterState(state);
              }}
            />
          </div>
          <div className=" ">
            {props.corporate.availableClinics &&
            filterSelectedData.state === null &&
            filterSelectedData.city === null
              ? Object.values(props.corporate.availableClinics).map(
                  (data, index) => (
                    <div key={index}>
                      <ProfileCards
                        title={data.clinicName}
                        subTitle={data.locality}
                        logo={
                          props.corporate.clinicsLogo &&
                          props.corporate.clinicsLogo[data.brandId]?.url
                        }
                        onCardClick={() => {
                          props.setSelectedClinic(data);
                          setSelectCheckupDateModal(true);
                        }}
                      />
                    </div>
                  )
                )
              : filterSelectedData.state !== null &&
                filterSelectedData.city !== null &&
                Object.values(props.corporate.availableClinics).map(
                  (data, index) =>
                    data.state === filterSelectedData?.state &&
                    data.city === filterSelectedData?.city && (
                      <div key={index}>
                        <ProfileCards
                          title={data.clinicName}
                          subTitle={data.locality}
                          logo={
                            props.corporate.clinicsLogo &&
                            props.corporate.clinicsLogo[data.brandId]?.url
                          }
                          onCardClick={() => {
                            props.setSelectedClinic(data);
                            setSelectCheckupDateModal(true);
                          }}
                        />
                      </div>
                    )
                )}
          </div>
        </div>
      </Modal>

      {/* FILTER MODAL */}
      {selectedRequest?.status !== "COMPLETED" &&
        showClinicFilterModal &&
        !selectCheckupDateModal && (
          <Modal
            show={
              showClinicFilterModal &&
              selectedRequest?.status !== "COMPLETED" &&
              !selectCheckupDateModal
            }
            canIgnore={true}
            onClose={() => {
              setShowClinicFilterModal(!showClinicFilterModal);
              setFilterState({ 0: "" });
            }}
            position={
              props.windowInnerWidth > 576
                ? ""
                : "position-fixed bottom-0 left-0 right-0"
            }
            width="inherit-parent-width"
            maxWidth={props.windowInnerWidth < 576 ? "" : "max-width-500px"}
            background="false"
            boxShadow="false"
            borderRadius="false"
            height="height-fit-to-content"
          >
            <div
              className={`background-white padding-larger ${
                props.windowInnerWidth < 576
                  ? "border-radius-top-default"
                  : "border-radius-default"
              } box-shadow-default font-family-gilroy-regular font-color-secondary`}
            >
              <div className="font-family-gilroy-medium text-align-center">
                Filters
              </div>

              <NativeSelect
                name="state"
                label="state"
                options={Object.values(filterState)}
                className="margin-top-medium"
                defaultValue={filterOnchangeSelectedData.state}
                value={filterOnchangeSelectedData.state}
                onChange={(event) => {
                  let city = { 0: "" };
                  props.corporate.availableClinics &&
                    Object.values(props.corporate.availableClinics).forEach(
                      (data) => {
                        if (event.target.value === data.state) {
                          city = { ...city, [data.city]: data.city };
                        }
                      }
                    );
                  setFilterCity(city);
                  setFilterOnchangeSelectedData({
                    ...(filterOnchangeSelectedData.city === null
                      ? { city: null }
                      : { city: Object.values(city)[1] }),
                    state: event.target.value
                  });
                }}
              />
              <NativeSelect
                name="city"
                options={Object.values(filterCity)}
                label="city"
                className="margin-top-medium"
                defaultValue={filterOnchangeSelectedData.city}
                value={filterOnchangeSelectedData.city}
                onChange={(event) => {
                  setFilterOnchangeSelectedData({
                    ...filterOnchangeSelectedData,
                    city: event.target.value
                  });
                }}
              />

              <div className=" flex-justify-content-space-around margin-top-large">
                <Button
                  boxShadow={false}
                  text="Apply"
                  type="button"
                  className=" margin-right-large"
                  disabled={
                    filterOnchangeSelectedData.state === null ||
                    filterOnchangeSelectedData.city === null
                  }
                  onClick={() => {
                    setFilterSelectedData(filterOnchangeSelectedData);
                    setShowClinicFilterModal(false);
                  }}
                />
                <Button
                  boxShadow={false}
                  text="Clear"
                  variant="secondary"
                  className=" margin-left-large"
                  onClick={() => {
                    setShowClinicFilterModal(false);
                    setFilterState({ 0: "" });
                    setFilterCity({ 0: "" });
                    setFilterOnchangeSelectedData({ state: null, city: null });
                    setFilterSelectedData({ state: null, city: null });
                  }}
                />
              </div>
            </div>
          </Modal>
        )}
      {/* DATE FIXING MODAL */}
      <Modal
        show={selectCheckupDateModal && selectedRequest?.status !== "COMPLETED"}
        canIgnore={true}
        onClose={() => {
          setSelectCheckupDateModal(!selectCheckupDateModal);
        }}
        position={
          props.windowInnerWidth > 576
            ? ""
            : "position-fixed bottom-0 left-0 right-0"
        }
        width="inherit-parent-width"
        maxWidth={props.windowInnerWidth < 576 ? "" : "max-width-500px"}
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-fit-to-content"
      >
        <div
          className={`background-white padding-larger ${
            props.windowInnerWidth < 576
              ? "border-radius-top-default"
              : "border-radius-default"
          } box-shadow-default font-family-gilroy-regular font-color-secondary`}
        >
          <div className="font-family-gilroy-medium text-align-center">
            Select checkup date
          </div>
          <div className=" position-absolute top-25px cursor-pointer">
            <img
              src={backIcon}
              alt="back Icon"
              onClick={() => {
                setSelectCheckupDateModal(false);
              }}
            />
          </div>
          <div className="font-family-gilroy-regular text-align-center font-size-small margin-top-medium">
            Before you book the appointment please contact the{" "}
            <span className="font-family-gilroy-medium">
              {`${props.corporate.selectedClinic?.clinicName}, ${props.corporate.selectedClinic?.locality} `}
            </span>{" "}
            and fix the date. Contact No:{" "}
            <span className="font-family-gilroy-medium">
              {props.corporate.selectedClinic?.phoneNumber}{" "}
            </span>
          </div>

          <InputBox
            type="date"
            minDate={new Date().toISOString().split("T")[0]}
            maxDate={
              selectedRequest &&
              new Date(selectedRequest?.requestedAt + 86400000 * 30)
                .toISOString()
                .split("T")[0]
            }
            required
            onChange={(event) => {
              setCheckupDate(+new Date(new Date(event.target.value)));
            }}
          />
          <Button
            boxShadow={false}
            data-cy="book-appointment-button"
            text="Book appointment"
            disabled={checkupDate === null}
            onClick={() => {
              if (checkupDate < selectedRequest?.expiringAt) {
                setSelectCheckupDateModal(false);
                setShowSelectClinicModal(false);
                props.setAppointmentForCheckup(selectedRequest?.requestId, {
                  appointedAt: checkupDate,
                  clinicBrandId: props.corporate.selectedClinic.brandId,
                  clinicId: props.corporate.selectedClinic.clinicId,
                  clinicLocality: props.corporate.selectedClinic.locality,
                  clinicName: props.corporate.selectedClinic.clinicName,
                  status: "APPOINTED"
                });
                props.navigate("/");
              } else {
                statusActions.setErrorStatus({
                  code: "custom",
                  message: "Please select the date before expiring"
                });
              }
            }}
          />
        </div>
      </Modal>
    </>
  );
}

const RemainingDays = (timestamp) => {
  const day = Math.round((timestamp - +new Date()) / 86400000);
  return `${day} DAY${day !== 1 && "S"} REMAINING`;
};

const mapStateToProps = function (state) {
  return {
    corporate: state.corporate
  };
};

const mapDispatchToProps = function () {
  return {
    clearSelectedRequest: () => corporateActions.clearSelectedRequest(),
    setSelectedClinic: (data) => corporateActions.setSelectedClinic(data),
    setAppointmentForCheckup: (requestId, data) =>
      corporateActions.setAppointmentForCheckup(requestId, data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedRequest);

function SelectedRequestSuspense() {
  return (
    <div>
      <div className=" flex-justify-content-space-between  flex-center-children-vertically">
        <div className=" flex-center-children-vertically">
          <div className="shimmer  border-radius-100-percentage padding-small margin-default height-and-width-84px">
            <div className="padding-medium margin-default border-radius-default" />
          </div>
          <div className="shimmer width-100px height-25px margin-right-default border-radius-default " />
        </div>
        <div className="shimmer width-100px height-25px margin-left-default border-radius-default" />
      </div>
      <div className="shimmer width-100px height-16px margin-right-default border-radius-default margin-top-medium" />
      <div className="shimmer width-250px height-25px margin-right-default border-radius-default margin-top-small" />
      <div className="shimmer border-radius-default height-4px margin-top-larger" />
      <div className=" flex-justify-content-space-between  flex-center-children-vertically margin-top-larger">
        <div className="shimmer width-150px height-25px margin-right-default border-radius-default " />
        <div className="shimmer width-30px height-25px margin-left-default border-radius-default" />
      </div>
      {[...Array(10).keys()].map((index) => (
        <div
          key={index}
          className="shimmer margin-large border-radius-default min-height-70px"
        />
      ))}
    </div>
  );
}
