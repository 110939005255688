import React, { useEffect, useState } from "react";
import "../../design-system.css";
import pdfIcon from "../../assets/PdfIcon.png";
import "./FHIRRenderer.css";
import RoundedProfilePicture from "../RoundedProfilePicture/RoundedProfilePicture";
import { dateAndTimeConverter } from "../../utils/constants";
import dropDownArrow from "../../assets/DropDownArrow.svg";
import { isValidObject } from "../../services/validators";
import PropTypes from "prop-types";

export default function FHIRRenderer(props) {
  const [medicineData, setMedicineData] = useState(null);
  const [conditionData, setConditionData] = useState(null);

  // for clubbing the data
  useEffect(() => {
    if (props.data) {
      let medicationAndMedicationRequest = {};
      let conditionAndMedicationRequest = {};
      props.data?.entry?.forEach((data) => {
        const resourceType = data?.resource?.resourceType;
        //if needed
        // fullData[data.fullUrl] = data;
        if (resourceType !== "") {
          if (resourceType === "Medication") {
            medicationAndMedicationRequest[data?.fullUrl] = {
              ...medicationAndMedicationRequest,
              medication: data
            };
          }
          if (resourceType === "Condition") {
            conditionAndMedicationRequest[data?.fullUrl] = {
              condition: data
            };
          }
          if (resourceType === "MedicationRequest") {
            if (data?.resource?.medicationReference) {
              medicationAndMedicationRequest[
                data?.resource?.medicationReference?.reference
              ] = {
                ...medicationAndMedicationRequest[
                  data?.resource?.medicationReference?.reference
                ],
                medicationRequest: data
              };
            }
            if (data?.resource?.reasonReference) {
              conditionAndMedicationRequest[
                data?.resource?.reasonReference[0]?.reference
              ] = {
                ...conditionAndMedicationRequest[
                  data?.resource?.reasonReference[0]?.reference
                ],
                medicationRequest: data
              };
            }
          }
        }
      });
      setMedicineData(medicationAndMedicationRequest);
      setConditionData(conditionAndMedicationRequest);
    }
  }, [props.data]);

  // for testing purpose
  // useEffect(() => {
  //   props.data?.entry?.forEach((data) => {
  //     const resourceType = data.resource.resourceType;
  //     console.log(resourceType);
  //   });
  //   // eslint-disable-next-line
  // }, []);

  return (
    <div data-cy="FHIR-Component" className="inherit-parent-width">
      {props.data?.entry?.map((data, index) => {
        const resourceType = data?.resource?.resourceType;
        if (resourceType === "Patient") {
          return <Patient key={index} data={data?.resource} />;
        }
        if (resourceType === "Practitioner") {
          return <Practitioner key={index} data={data?.resource} />;
        }
        if (resourceType === "Encounter") {
          return <Encounter key={index} data={data?.resource} />;
        }
        if (resourceType === "Composition") {
          return <DateAndTime key={index} data={data?.resource} />;
        }
        if (resourceType === "DocumentReference") {
          return (
            <DocumentReference
              key={index}
              data={data?.resource}
              documentOnClick={(document) => {
                props.documentOnClick(document);
              }}
            />
          );
        }
        if (resourceType === "Immunization") {
          return <Immunization key={index} data={data?.resource} />;
        }
        if (resourceType === "Appointment") {
          return <Appointment key={index} data={data?.resource} />;
        }
        if (resourceType === "CarePlan") {
          return <CarePlan key={index} data={data?.resource} />;
        }
        if (resourceType === "Condition") {
          return <Condition key={index} data={data?.resource} />;
        }
        if (resourceType === "AllergyIntolerance") {
          return <AllergyIntolerance key={index} data={data?.resource} />;
        }
        if (resourceType === "Procedure") {
          return <Procedure key={index} data={data?.resource} />;
        }
        if (resourceType === "Observation") {
          return <Observation key={index} data={data?.resource} />;
        }
        if (resourceType === "DiagnosticReport") {
          return <DiagnosticReport key={index} data={data?.resource} />;
        }
        if (resourceType === "Organization" && data.resource.identifier) {
          return <Organization key={index} data={data?.resource} />;
        } else {
          return <div key={index}></div>;
        }
      })}
      {isValidObject(medicineData) &&
        Object.values(medicineData).map(
          (data, index) =>
            Object.keys(data).includes("medication") && (
              <Medication key={index} data={data} />
            )
        )}
      {isValidObject(conditionData) &&
        Object.values(conditionData).map(
          (data, index) =>
            Object.keys(data).includes("medicationRequest") && (
              <Condition key={index} data={data} />
            )
        )}
    </div>
  );
}

const Practitioner = (props) => {
  const name = props?.data?.name[0];
  return (
    <div className="padding-larger" data-cy="Practitioner-card">
      <div className="font-size-small text-uppercase">Practitioner</div>
      <div className=" flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
        <div className="">
          <div className="font-size-large">
            {name?.prefix[0] ?? ""}.{name?.text ?? ""}
          </div>
          <div className="font-size-small padding-top-small">
            {name?.suffix[0] ?? ""}
          </div>
        </div>
        <div>
          <RoundedProfilePicture
            data-cy="Practitioner-profile-picture"
            className="margin-top-default"
            size={"medium"}
            // src={googleFit}
          />
        </div>
      </div>
    </div>
  );
};

const Patient = (props) => {
  const name = props?.data?.name[0];
  return (
    <div className="padding-larger" data-cy="Patient-card">
      <div className="font-size-small text-uppercase">Patient</div>
      <div className="flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
        <div className="padding-top-small">
          <div className="font-size-large">{name?.text ?? ""}</div>
          <div className="font-size-small padding-top-default">
            {props.data?.id ?? ""}
          </div>
          <div className="font-size-small padding-top-small  text-transform-capitalize ">
            {props.data?.gender ?? ""}
          </div>
        </div>
        <div>
          <RoundedProfilePicture
            data-cy="Patient-profile-picture"
            className="margin-top-default"
            size={"medium"}
            // src={googleFit}
          />
        </div>
      </div>
    </div>
  );
};

const Encounter = (props) => {
  return (
    <div className="padding-larger" data-cy="Encounter-card">
      <div className="font-size-small text-uppercase">Encounter</div>
      <div className="flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
        <div className="padding-top-small">
          <div className="font-size-large">
            {props.data.class.display ?? ""}
          </div>
        </div>
        <div>
          <div
            data-cy="status-card"
            className="padding-top-small padding-bottom-small padding-right-large padding-left-large border-radius-default font-color-primary font-size-small text-capitalize font-color-light-green"
          >
            {props.data.status ?? ""}
          </div>
        </div>
      </div>
    </div>
  );
};

const DateAndTime = (props) => {
  const dataAndTime = +new Date(props.data.date);
  return (
    <div
      className="padding-larger flex-direction-row"
      data-cy="DateAndTime-card"
    >
      <div className=" width-50-percentage text-align-center">
        <div className="font-size-small text-uppercase">Date</div>
        <div className=" padding-top-default">
          {dateAndTimeConverter(dataAndTime, "secondDate")}
        </div>
      </div>
      <div className=" width-50-percentage text-align-center">
        <div className="font-size-small">Time</div>
        <div className=" padding-top-default">
          {dateAndTimeConverter(dataAndTime, "Time")}
        </div>
      </div>
    </div>
  );
};

const Medication = (props) => {
  const [showData, setShowData] = useState(false);
  const medicine = props.data?.medication?.resource?.code;
  const medicineInfo = props.data?.medicationRequest?.resource;
  return (
    <div
      className="padding-larger"
      data-cy={`Medication-card-${
        medicine?.coding ? medicine?.coding[0]?.display : medicine?.text
      }`}
    >
      <div className="font-size-small text-uppercase">Medication</div>
      <div className="flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
        <div className="padding-top-small">
          <div className="font-size-large">
            {medicine?.coding ? medicine?.coding[0]?.display : medicine?.text}
          </div>
        </div>
        {Object.keys(props.data).includes("medicationRequest") && (
          <DropDownButton
            dropDownButton-data-cy={`Medication-dropDown-${
              medicine?.coding ? medicine?.coding[0]?.display : medicine?.text
            }`}
            dropDownClick={() => {
              if (showData === false) {
                setShowData(true);
              } else {
                setShowData(false);
              }
            }}
            showData={showData}
          />
        )}
      </div>
      {showData === true &&
        Object.keys(props.data).includes("medicationRequest") && (
          <div className=" padding-top-larger">
            <div className="font-size-small text-uppercase">
              Dosage Instruction
            </div>
            {medicineInfo.dosageInstruction.map((data, index) => (
              <div
                className="font-size-large"
                key={`medicationRequest-${index}`}
              >
                {data.text}
              </div>
            ))}
          </div>
        )}
    </div>
  );
};

const DocumentReference = (props) => {
  // console.log(props.data);
  const resource = props.data?.content[0];
  // console.log(resource.attachment?.data);
  const extension = resource?.attachment?.contentType?.split("/")[1];

  // console.log(base64ToArrayBuffer(resource.attachment?.data));
  const documentBuffer = base64ToArrayBuffer(resource.attachment?.data);

  function base64ToArrayBuffer(base64) {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  return (
    <div className="padding-larger" data-cy="DocumentReference-card">
      <div className="font-size-small text-uppercase">Reference</div>
      <div
        className=" flex-justify-content-space-between flex-direction-column"
        onClick={() => {
          props.documentOnClick(documentBuffer);
        }}
      >
        <div className="padding-top-small">
          <div className="font-size-large">
            {resource?.attachment?.title ?? ""}.{extension ?? ""}
          </div>
        </div>
        <div className=" inherit-parent-width padding-top-large padding-bottom-default">
          <div className=" inherit-parent-height border-radius-default flex-center-children-horizontally flex-center-children-vertically height-120px background-color-grey">
            <img
              data-cy="PDF-icon"
              draggable="false"
              className="height-width-48px"
              src={pdfIcon}
              alt="pdf icon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Organization = (props) => {
  const [showData, setShowData] = useState(false);
  const name = props.data?.name?.split(",")[0];
  const area = props.data?.name?.split(",")[1];
  const address = props.data?.address[0];
  const phoneNumber = props.data?.telecom;
  return (
    <div className="padding-larger" data-cy="Organization-card">
      <div className="font-size-small text-uppercase">Organization</div>
      <div className="flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
        <div className="padding-top-small">
          <div className="font-size-large">{name ?? ""}</div>
          <div className="font-size-small padding-top-small">{area ?? ""}</div>
        </div>
        <div className=" flex-center-children-horizontally flex-center-children-vertically">
          <RoundedProfilePicture
            data-cy="Organization-profile-picture"
            className="margin-top-default"
            size={"medium"}
            // src={googleFit}
          />
          <DropDownButton
            dropDownButton-data-cy="Organization-dropDown"
            className=" padding-left-large"
            dropDownClick={() => {
              if (showData === false) {
                setShowData(true);
              } else {
                setShowData(false);
              }
            }}
            showData={showData}
          />
        </div>
      </div>
      {showData === true && (
        <div className="padding-top-larger">
          <div className="font-size-small text-uppercase">Contact</div>
          {phoneNumber.map((data, index) => (
            <div className="font-size-large" key={`organization-${index}`}>
              {data.system} : {data.value}
            </div>
          ))}
          <div className="padding-top-large">
            {address?.line ?? ""} - {address.postalCode ?? ""}
          </div>
        </div>
      )}
    </div>
  );
};

const Immunization = (props) => {
  const [showData, setShowData] = useState(false);
  const vaccine = props.data?.vaccineCode;
  return (
    <div
      className="padding-larger"
      data-cy={`Immunization-card-${
        vaccine?.coding ? vaccine?.coding[0]?.display : vaccine?.text
      }`}
    >
      <>
        <div className="font-size-small text-uppercase">Immunization</div>
        <div className=" flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
          <div className="padding-top-small">
            <div className="font-size-large">
              {vaccine?.coding ? vaccine?.coding[0]?.display : vaccine?.text}
            </div>
            <div className="font-size-small padding-top-small">
              {/* {Dose} {" : "} {vaccine.protocolApplied[0].doseNumberString} */}
            </div>
          </div>
          <DropDownButton
            dropDownButton-data-cy={`Immunization-dropDown-${
              vaccine?.coding ? vaccine?.coding[0]?.display : vaccine?.text
            }`}
            dropDownClick={() => {
              if (showData === false) {
                setShowData(true);
              } else {
                setShowData(false);
              }
            }}
            showData={showData}
          />
        </div>
      </>

      {showData === true && (
        <div className=" padding-top-larger">
          <div className="font-size-small text-uppercase">Manufacturer</div>
          <div className="font-size-large">
            {props.data?.manufacturer?.reference ?? ""}
          </div>
        </div>
      )}
    </div>
  );
};

const Observation = (props) => {
  const [showData, setShowData] = useState(false);
  return (
    <div
      className="padding-larger"
      data-cy={`Observation-card-${props.data?.code?.text}`}
    >
      <div className="font-size-small text-uppercase">Observation</div>
      <div className=" flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
        <div className="padding-top-small">
          {props.data?.code?.text && !props.data.valueQuantity && (
            <div className="font-size-large padding-top-small">
              {props.data?.code?.text ?? ""}
              {!props.data?.component ? " : " : ""}
              {props.data?.valueString ?? ""}
            </div>
          )}

          {props.data?.code?.coding && props.data?.valueQuantity && (
            <div className="font-size-large padding-top-small">
              {props.data?.code?.coding[0]?.display ?? ""}
              {" : "}
              {props.data?.valueQuantity?.value ?? ""}{" "}
              {props.data.valueQuantity?.unit ?? ""}
            </div>
          )}

          {props.data?.code?.coding && props.data?.valueCodeableConcept && (
            <div className="font-size-large padding-top-small">
              {props.data?.code?.coding[0]?.display ?? ""}
              {" : "}
              {props.data?.valueCodeableConcept?.text ?? ""}
            </div>
          )}

          {/* div can be present */}

          {props.data?.code?.text && props.data?.valueQuantity && (
            <div className="font-size-large padding-top-small">
              {props.data?.code?.text ?? ""}
              {" : "}
              {props.data.valueQuantity.value ?? ""}{" "}
              {props.data.valueQuantity.unit ?? ""}
            </div>
          )}
        </div>

        {props.data.component && (
          <DropDownButton
            dropDownButton-data-cy="Observation-dropDown"
            dropDownClick={() => {
              if (showData === false) {
                setShowData(true);
              } else {
                setShowData(false);
              }
            }}
            showData={showData}
          />
        )}
      </div>
      {showData === true && (
        <div className=" padding-top-larger">
          {props.data.component.map((data, index) => (
            <div
              key={`Observation-${index}`}
              className={`font-size-small ${
                index === 0 ? "" : "padding-top-large"
              } text-uppercase`}
            >
              {data.code?.coding[0]?.display ?? ""} {" : "}{" "}
              {data.valueQuantity?.value ?? ""} {data.valueQuantity.unit ?? ""}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Condition = (props) => {
  const [showData, setShowData] = useState(false);
  const dataAndTime = props.data?.recordedDate
    ? +new Date(props.data.recordedDate)
    : "";

  const condition =
    isValidObject(props.data) &&
    Object.keys(props.data).includes("medicationRequest")
      ? props.data.condition.resource
      : "";

  const medicationRequest =
    isValidObject(props.data) &&
    Object.keys(props.data).includes("medicationRequest")
      ? props.data.medicationRequest.resource
      : "";

  return (
    <div className="padding-larger" data-cy="Condition-card">
      <div className="font-size-small text-uppercase">Condition</div>
      <div className="flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
        <div className="padding-top-small">
          <div className="font-size-large">
            {condition ? condition.code.text : props.data.code.text}
          </div>
        </div>
        <DropDownButton
          dropDownButton-data-cy={`Condition-${
            condition ? condition.code.text : props.data.code.text
          }-dropDown`}
          dropDownClick={() => {
            if (showData === false) {
              setShowData(true);
            } else {
              setShowData(false);
            }
          }}
          showData={showData}
        />
      </div>
      {showData === true && (
        <div className=" padding-top-larger">
          {props.data?.severity && (
            <div className="font-size-small text-uppercase">
              Severity
              <div className="font-size-large padding-top-default">
                {props.data?.severity?.text}
              </div>
            </div>
          )}

          {props.data?.recordedDate && (
            <div className="font-size-small padding-top-large text-uppercase">
              Recorded Date
              <div className="font-size-large padding-top-default">
                {dateAndTimeConverter(dataAndTime, "secondDate")} {" | "}
                {dateAndTimeConverter(dataAndTime, "Time")}
              </div>
            </div>
          )}

          {medicationRequest && (
            <div className="font-size-small text-uppercase">
              Medicine
              <div className="font-size-large">
                {medicationRequest?.medicationCodeableConcept?.text ?? ""}
              </div>
            </div>
          )}

          {medicationRequest && (
            <div className="font-size-small padding-top-large text-uppercase">
              Dosage Instruction
              <div className="font-size-large">
                {medicationRequest?.dosageInstruction[0]?.text ?? ""}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const Appointment = (props) => {
  const [showData, setShowData] = useState(false);
  const endDataAndTime = +new Date(props.data?.end);
  const StartDataAndTime = +new Date(props.data?.start);

  return (
    <div className="padding-larger" data-cy="Appointment-card">
      <div className="font-size-small text-uppercase">Appointment</div>
      <div className="flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
        <div className="padding-top-small">
          <div className="font-size-large">{props.data?.description ?? ""}</div>
        </div>

        <DropDownButton
          dropDownButton-data-cy="Appointment-dropDown"
          dropDownClick={() => {
            if (showData === false) {
              setShowData(true);
            } else {
              setShowData(false);
            }
          }}
          showData={showData}
        />
      </div>
      {showData === true && (
        <div className=" padding-top-larger">
          <div className="font-size-small text-uppercase">Timing</div>
          <div className="font-size-large padding-top-default">
            Start:{dateAndTimeConverter(StartDataAndTime, "secondDate")} {" | "}
            {dateAndTimeConverter(StartDataAndTime, "Time")}
          </div>
          <div className="font-size-large padding-top-default">
            End:{dateAndTimeConverter(endDataAndTime, "secondDate")} {" | "}
            {dateAndTimeConverter(endDataAndTime, "Time")}
          </div>
        </div>
      )}
    </div>
  );
};

const CarePlan = (props) => {
  const [showData, setShowData] = useState(false);
  const endDataAndTime = +new Date(props.data?.period?.end);
  const StartDataAndTime = +new Date(props.data?.period?.start);
  return (
    <div className="padding-larger" data-cy="CarePlan-card">
      <div className="font-size-small text-uppercase">CarePlan</div>
      <div className="flex-center-children-vertically padding-top-default flex-justify-content-space-between flex-direction-row">
        <div className="padding-top-small">
          <div className="font-size-large">{props.data?.description ?? ""}</div>
        </div>
        <DropDownButton
          dropDownButton-data-cy="CarePlan-dropDown"
          dropDownClick={() => {
            if (showData === false) {
              setShowData(true);
            } else {
              setShowData(false);
            }
          }}
          showData={showData}
        />
      </div>
      {showData === true && (
        <div className=" padding-top-larger">
          <div className="font-size-small text-uppercase">Timing</div>
          <div className="font-size-large padding-top-default">
            Start:{dateAndTimeConverter(StartDataAndTime, "secondDate")}
            {" | "}
            {dateAndTimeConverter(StartDataAndTime, "Time")}
          </div>
          <div className="font-size-large padding-top-default">
            End:{dateAndTimeConverter(endDataAndTime, "secondDate")} {" | "}
            {dateAndTimeConverter(endDataAndTime, "Time")}
          </div>
        </div>
      )}
    </div>
  );
};

const AllergyIntolerance = (props) => {
  const [showData, setShowData] = useState(false);
  return (
    <div
      className="padding-larger"
      data-cy={`AllergyIntolerance-card-${props.data?.category ?? ""}`}
    >
      <div className="font-size-small text-uppercase">Allergy</div>
      <div className=" flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
        <div className="padding-top-small">
          <div className="font-size-large">
            {props.data?.category ?? ""}
            {" : "}
            {props.data?.code?.coding[0]?.display ?? ""}
          </div>
        </div>
        <DropDownButton
          dropDownButton-data-cy={`AllergyIntolerance-dropDown-${
            props.data?.category ?? ""
          }`}
          dropDownClick={() => {
            if (showData === false) {
              setShowData(true);
            } else {
              setShowData(false);
            }
          }}
          showData={showData}
        />
      </div>
      {showData === true && (
        <div className=" padding-top-larger">
          <div className="font-size-small text-uppercase">Severity</div>
          <div className="font-size-large padding-top-default">
            {props.data?.criticality ?? ""}
          </div>
          <div className="font-size-small padding-top-large text-uppercase">
            Duration
          </div>
          <div className="font-size-large padding-top-default">
            {props.data?.onsetString ?? ""}
          </div>
          {props.data?.note && (
            <div className="font-size-small padding-top-large text-uppercase">
              Notes
              {props.data.note.map((data, index) => (
                <div
                  key={`allergyIntolerance${index}`}
                  className="font-size-large text-transfrom-lowecase"
                >
                  {data.text}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const Procedure = (props) => {
  const [showData, setShowData] = useState(false);
  const dataAndTime = +new Date(props.data.performedDateTime);
  return (
    <div className="padding-larger" data-cy="Procedure-card">
      <div className="font-size-small text-uppercase">Procedure</div>
      <div className="flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
        <div className="padding-top-small">
          <div className="font-size-large">
            {props.data?.code?.coding[0]?.display ?? ""}
          </div>
        </div>
        <DropDownButton
          dropDownButton-data-cy="Procedure-dropDown"
          dropDownClick={() => {
            if (showData === false) {
              setShowData(true);
            } else {
              setShowData(false);
            }
          }}
          showData={showData}
        />
      </div>
      {showData === true && (
        <div className=" padding-top-larger">
          <div className="font-size-small padding-top-large text-uppercase">
            Performed Date
          </div>
          <div className="font-size-large">
            {dateAndTimeConverter(dataAndTime, "secondDate")} {" | "}
            {dateAndTimeConverter(dataAndTime, "Time")}
          </div>
          <div className="font-size-small padding-top-large text-uppercase">
            Complications
            {props.data.complication.map((data, index) => (
              <div key={`Procedure-${index}`} className="font-size-large">
                {data.coding[0]?.display ?? ""}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const DiagnosticReport = (props) => {
  const [showData, setShowData] = useState(false);
  return (
    <div
      className="padding-larger"
      data-cy={`DiagnosticReport-card-${
        props.data?.code?.text
          ? props.data?.code?.text
          : props.data?.code?.coding[0]?.display
      }`}
    >
      <div className="font-size-small text-uppercase">DiagnosticReport</div>
      <div className=" flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
        <div className="padding-top-small">
          <div className="font-size-large">
            {props.data?.code?.text
              ? props.data?.code?.text
              : props.data?.code?.coding[0]?.display}
          </div>
        </div>
        <DropDownButton
          dropDownButton-data-cy={`DiagnosticReport-dropDown-${
            props.data?.code?.text
              ? props.data?.code?.text
              : props.data?.code?.coding[0]?.display
          }`}
          dropDownClick={() => {
            if (showData === false) {
              setShowData(true);
            } else {
              setShowData(false);
            }
          }}
          showData={showData}
        />
      </div>
      {showData === true && (
        <div className=" padding-top-larger">
          <div className="font-size-small text-uppercase">
            Conclusion
            <div className="font-size-large text-lowercase">
              {props.data?.conclusion ?? ""}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const DropDownButton = (props) => {
  return (
    <div
      className={`${props.className} cursor-pointer`}
      onClick={props.dropDownClick}
      data-cy={props["dropDownButton-data-cy"]}
    >
      <img
        className={`${
          props.showData === true ? "rotate-180" : ""
        } padding-small`}
        src={dropDownArrow}
        alt="dropdown"
      />
    </div>
  );
};

FHIRRenderer.propTypes = {
  data: PropTypes.object,
  documentOnClick: PropTypes.func
};

FHIRRenderer.defaultProps = {
  data: null,
  documentOnClick: undefined
};
