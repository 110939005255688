import React, { useRef, useEffect, useState } from "react";
import success from "../../assets/success.svg";
import error from "../../assets/error.svg";
import close from "../../assets/close.svg";
import "./SnackBar.css";
import PropTypes from "prop-types";

function SnackBar(props) {
  const firstSnackBar = useRef(null);
  const secondSnackBar = useRef(null);

  const firstSnackBarStatusRef = useRef("DONE");
  const secondSnackBarStatusRef = useRef("DONE");

  const [firstSnackBarStatus, setFirstSnackBarStatus] = useState("DONE");
  const [secondSnackBarStatus, setSecondSnackBarStatus] = useState("DONE");

  const setTimeoutInterval = 750;

  const [snackBarsContents, setSnackBarsContents] = useState({
    firstSnackBar: {
      type: "",
      message: ""
    },
    secondSnackBar: {
      type: "",
      message: ""
    }
  });

  const snackBarsContentsRef = useRef({
    firstSnackBar: {
      type: "",
      message: ""
    },
    secondSnackBar: {
      type: "",
      message: ""
    }
  });

  useEffect(() => {
    if (
      typeof props.message === "string" &&
      props.message.length !== 0 &&
      snackBarsContentsRef.current.firstSnackBar.message === "" &&
      !firstSnackBar.current.className.includes("show")
    ) {
      if (firstSnackBarStatusRef.current === "DONE") {
        snackBarsContentsRef.current = {
          ...snackBarsContentsRef.current,
          firstSnackBar: {
            type: props.type,
            message: props.message
          }
        };
        setSnackBarsContents({ ...snackBarsContentsRef.current });
        showFirstSnackBar();
      }
    } else if (
      typeof props.message === "string" &&
      props.message.length !== 0 &&
      snackBarsContentsRef.current.secondSnackBar.message === "" &&
      !secondSnackBar.current.className.includes("show")
    ) {
      if (secondSnackBarStatusRef.current === "DONE") {
        snackBarsContentsRef.current = {
          ...snackBarsContentsRef.current,
          secondSnackBar: {
            type: props.type,
            message: props.message
          }
        };
        setSnackBarsContents({ ...snackBarsContentsRef.current });
        showSecondSnackBar();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.message, firstSnackBarStatus, secondSnackBarStatus]);

  const showFirstSnackBar = () => {
    firstSnackBarStatusRef.current = "PROCESSING";
    if (secondSnackBar.current.className.includes("show")) {
      hideSecondSnackBar();
    }
    firstSnackBar.current.className += " show";
    setTimeout(() => {
      if (
        firstSnackBar.current &&
        firstSnackBar.current.className.includes("show")
      ) {
        hideFirstSnackBar();
      }
      firstSnackBarStatusRef.current = "DONE";
      setFirstSnackBarStatus("DONE");
    }, setTimeoutInterval * 2.5);
  };

  const hideFirstSnackBar = () => {
    if (
      typeof props.status.message === "string" &&
      typeof props.removeStatus === "function"
    ) {
      props.removeStatus();
    }
    firstSnackBar.current.className = firstSnackBar.current.className.replace(
      "show",
      "fadeOut"
    );
    snackBarsContentsRef.current = {
      ...snackBarsContentsRef.current,
      firstSnackBar: {
        type: "",
        message: ""
      }
    };
    setTimeout(() => {
      setSnackBarsContents({ ...snackBarsContentsRef.current });
    }, setTimeoutInterval / 2);
    setTimeout(() => {
      if (firstSnackBar.current && firstSnackBar.current.className) {
        firstSnackBar.current.className =
          firstSnackBar.current.className &&
          firstSnackBar.current.className.replace("fadeOut", "");
      }
    }, setTimeoutInterval);
  };

  const showSecondSnackBar = () => {
    secondSnackBarStatusRef.current = "PROCESSING";
    if (firstSnackBar.current.className.includes("show")) {
      hideFirstSnackBar();
    }
    secondSnackBar.current.className += " show";
    setTimeout(() => {
      if (secondSnackBar.current.className.includes("show")) {
        hideSecondSnackBar();
      }
      secondSnackBarStatusRef.current = "DONE";
      setSecondSnackBarStatus("DONE");
    }, setTimeoutInterval * 2.5);
  };

  const hideSecondSnackBar = () => {
    if (
      typeof props.status.message === "string" &&
      typeof props.removeStatus === "function"
    ) {
      props.removeStatus();
    }
    secondSnackBar.current.className = secondSnackBar.current.className.replace(
      "show",
      "fadeOut"
    );
    snackBarsContentsRef.current = {
      ...snackBarsContentsRef.current,
      secondSnackBar: {
        type: "",
        message: ""
      }
    };
    setTimeout(() => {
      setSnackBarsContents({ ...snackBarsContentsRef.current });
    }, setTimeoutInterval / 2);
    setTimeout(() => {
      secondSnackBar.current.className =
        secondSnackBar.current.className.replace("fadeOut", "");
    }, setTimeoutInterval);
  };

  return (
    <React.Fragment>
      <section
        data-cy="snackBar"
        className="snackbar inherit-parent-width"
        ref={firstSnackBar}
      >
        <div className="box-shadow-default font-color-secondary font-size-small padding-medium inherit-parent-width font-family-gilroy-medium background-white flex-justify-content-space-between text-align-center font-size-small border-radius-default">
          {snackBarsContents.firstSnackBar.type === "success" && (
            <img
              className="margin-vertical-auto"
              src={success}
              alt="snackbar-icon"
            />
          )}
          {snackBarsContents.firstSnackBar.type === "error" && (
            <img
              className="margin-vertical-auto"
              src={error}
              alt="snackbar-icon"
            />
          )}
          <div className="max-width-75-percentage text-overflow-ellipsis">
            {snackBarsContents.firstSnackBar.message}
          </div>

          <img
            data-cy="snackBarCloseIcon"
            src={close}
            alt="snackbar-icon"
            onClick={hideFirstSnackBar}
          />
        </div>
      </section>

      <section className="snackbar inherit-parent-width" ref={secondSnackBar}>
        <div className="box-shadow-default font-color-secondary font-size-small padding-medium inherit-parent-width font-family-gilroy-medium background-white flex-justify-content-space-between text-align-center font-size-small border-radius-default">
          <img
            className="margin-vertical-auto"
            src={
              snackBarsContents.secondSnackBar.type === "success"
                ? success
                : error
            }
            alt="snackbar-icon"
          />
          <div className="max-width-75-percentage text-overflow-ellipsis">
            {snackBarsContents.secondSnackBar.message}
          </div>

          <img
            data-cy="snackBarCloseIcon"
            src={close}
            alt="snackbar-icon"
            onClick={hideSecondSnackBar}
          />
        </div>
      </section>
    </React.Fragment>
  );
}
SnackBar.propTypes = {
  /**
   * message content which you want to display
   */
  message: PropTypes.string,
  /**
   * snack bar type, types are `success` || `error`
   */
  type: PropTypes.oneOf(["success", "error"]),
  /**
   * input object which have message and code keys
   */
  status: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.any
  }),
  /**
   * callback function to remove the snackbar
   */
  removeStatus: PropTypes.func
};

SnackBar.defaultProps = {
  message: null,
  type: "error",
  status: {
    message: null,
    code: null
  }
};

export default SnackBar;
