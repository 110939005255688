import { all, put, takeEvery } from "@redux-saga/core/effects";
import { editProfile } from "../../services/database";
import { isOnline } from "../../utils/validators";
import store from "../store/store";
import { statusActions } from "./statusSaga";

export const actionTypes = {
  SET_ACCOUNT_PROFILES: "SET_ACCOUNT_PROFILES",
  CREATE_PROFILE: "CREATE_PROFILE",
  PROFILE_PICTURE: "PROFILE_PICTURE",
  SET_PROFILE_PICTURE_URL: "SET_PROFILE_PICTURE_URL",
  EDIT_PROFILE: "EDIT_PROFILE"
};

export const profileActions = {
  //create patient profile

  setAccountProfiles: (profiles) => {
    store.dispatch({
      type: actionTypes.SET_ACCOUNT_PROFILES,
      payload: {
        profiles: profiles
      }
    });
  },

  // EDIT PROFILE
  editProfile: (data) => {
    store.dispatch({
      type: actionTypes.EDIT_PROFILE,
      payload: {
        data: data
      }
    });
  }
};

function* setProfilesWorker(action) {
  try {
    yield put({
      type: "SET_PROFILES",
      payload: action.payload.profiles
    });
  } catch (error) {
    statusActions.setErrorStatus(error);
  }
}

// edit profile
export function* editProfileWorker(action) {
  try {
    if (isOnline()) {
      yield setProfileLoading(true);
      const profileId =
        store.getState().profile.data &&
        Object.keys(store.getState().profile.data)[0];
      yield editProfile(action.payload.data, profileId);
      yield setProfileLoading(false);
      statusActions.setSuccessStatus("Successfully saved");
    }
  } catch (error) {
    statusActions.setErrorStatus(error);
    yield setProfileLoading(false);
  }
}

export function* profileWatcher() {
  yield all([
    takeEvery("SET_ACCOUNT_PROFILES", setProfilesWorker),
    takeEvery("EDIT_PROFILE", editProfileWorker)
  ]);
}

function* setProfileLoading(bool) {
  yield put({
    type: "SET_PROFILE_LOADING",
    payload: {
      loading: bool
    }
  });
}
