export const LogoutIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : 16}
      height={props.height ? props.height : 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.6667 0C1.95945 0 1.28116 0.280952 0.781058 0.781049C0.280955 1.28115 0 1.95942 0 2.66667V13.3333C0 14.0406 0.280955 14.7189 0.781058 15.219C1.28116 15.719 1.95945 16 2.6667 16H7.1112C7.34695 16 7.57304 15.9064 7.73974 15.7397C7.90644 15.573 8.00009 15.3469 8.00009 15.1111C8.00009 14.8754 7.90644 14.6493 7.73974 14.4826C7.57304 14.3159 7.34695 14.2222 7.1112 14.2222H2.6667C2.43095 14.2222 2.20485 14.1286 2.03815 13.9619C1.87145 13.7952 1.7778 13.5691 1.7778 13.3333V2.66667C1.7778 2.43092 1.87145 2.20483 2.03815 2.03813C2.20485 1.87143 2.43095 1.77778 2.6667 1.77778H7.1112C7.34695 1.77778 7.57304 1.68413 7.73974 1.51743C7.90644 1.35073 8.00009 1.12464 8.00009 0.888889C8.00009 0.653141 7.90644 0.427049 7.73974 0.26035C7.57304 0.0936505 7.34695 0 7.1112 0H2.6667Z"
        fill={props.color}
      />
      <path
        d="M10.9272 3.81591C11.0939 3.64927 11.32 3.55566 11.5557 3.55566C11.7914 3.55566 12.0175 3.64927 12.1841 3.81591L15.7397 7.37147C15.9064 7.53816 16 7.76421 16 7.99991C16 8.23561 15.9064 8.46167 15.7397 8.62836L12.1841 12.1839C12.0165 12.3458 11.792 12.4354 11.5589 12.4334C11.3258 12.4314 11.1029 12.3379 10.9381 12.1731C10.7733 12.0083 10.6798 11.7853 10.6778 11.5523C10.6757 11.3192 10.7653 11.0947 10.9272 10.927L12.9655 8.8888H6.2223C5.98655 8.8888 5.76045 8.79515 5.59375 8.62845C5.42705 8.46175 5.3334 8.23566 5.3334 7.99991C5.3334 7.76416 5.42705 7.53807 5.59375 7.37137C5.76045 7.20468 5.98655 7.11102 6.2223 7.11102H12.9655L10.9272 5.0728C10.7606 4.90611 10.667 4.68006 10.667 4.44436C10.667 4.20865 10.7606 3.9826 10.9272 3.81591Z"
        fill={props.color}
      />
    </svg>
  );
};

export const RotateIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : "14"}
      height={props.height ? props.height : "16"}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6 1.8337C11.2234 0.646152 9.4897 -0.00254483 7.70033 0.000498944C6.00088 0.000374291 4.34933 0.583725 3.00266 1.66087C1.65613 2.73805 0.690298 4.24766 0.255917 5.95463C-0.178584 7.66172 -0.0573301 9.4702 0.600781 11.098C1.25901 12.726 2.4169 14.0819 3.89434 14.9545C5.37147 15.8272 7.08524 16.1675 8.76823 15.9226C10.4511 15.6776 12.0088 14.8609 13.1984 13.6C13.3328 13.4633 13.4087 13.276 13.4087 13.0806C13.4088 12.8851 13.3331 12.6978 13.1988 12.561C13.0645 12.4241 12.8828 12.3493 12.6947 12.3531C12.5066 12.357 12.328 12.4395 12.199 12.5818C11.6143 13.2058 10.9146 13.7011 10.1417 14.0384C9.36891 14.3758 8.53867 14.5482 7.70047 14.5454C6.35161 14.5454 5.03833 14.0956 3.95393 13.2623C2.86951 12.4291 2.07117 11.2559 1.67638 9.91603C1.28158 8.57619 1.31113 7.13989 1.76075 5.81864C2.21025 4.4975 3.05618 3.36095 4.17387 2.5765C5.29153 1.79206 6.622 1.40112 7.96961 1.46102C9.31715 1.52105 10.6106 2.02875 11.6597 2.90963H10.5001C10.25 2.90963 10.019 3.04817 9.89393 3.27318C9.7689 3.49818 9.7689 3.77553 9.89393 4.00052C10.019 4.22552 10.25 4.36407 10.5001 4.36407H13.3001C13.4857 4.36407 13.6637 4.28749 13.7949 4.15113C13.9263 4.01477 14 3.8297 14 3.63685V0.727844C14 0.468024 13.8665 0.227935 13.65 0.0980248C13.4334 -0.031885 13.1666 -0.031885 12.95 0.0980248C12.7335 0.227935 12.6 0.468028 12.6 0.727844V1.8337Z"
        fill={props.color ? props.color : "white"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.72042 9.02898L6.71038 10.0575C6.97311 10.3299 7.3291 10.4828 7.70022 10.4828C8.07133 10.4828 8.42733 10.3299 8.69005 10.0575L9.68001 9.02898C9.94216 8.75601 10.0893 8.38613 10.0893 8.00055C10.0893 7.61496 9.94215 7.24508 9.68001 6.97212L8.69005 5.94356C8.42733 5.67122 8.07133 5.51818 7.70022 5.51818C7.3291 5.51818 6.9731 5.6712 6.71038 5.94356L5.72042 6.97212C5.4583 7.24509 5.311 7.61496 5.311 8.00055C5.311 8.38613 5.45829 8.75601 5.72042 9.02898Z"
        fill={props.color ? props.color : "white"}
      />
    </svg>
  );
};

export const DeleteIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : "16"}
      height={props.height ? props.height : "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0038 2.00774C14.0038 1.87881 13.8962 1.77429 13.7636 1.77429H11.0016L10.1439 0.996094H5.85516L4.99741 1.77429H2.23547C2.10283 1.77429 1.9953 1.87881 1.9953 2.00774V2.68972C1.9953 2.81865 2.10283 2.92318 2.23547 2.92318H5.85516L6.68102 2.10783H9.36273L10.1439 2.92318H13.7636C13.8962 2.92318 14.0038 2.81865 14.0038 2.68972V2.00774Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
      <path
        d="M5.95504 6.5001C5.94954 6.36758 6.05857 6.25723 6.19501 6.25723H6.92088C7.05352 6.25723 7.16105 6.36175 7.16105 6.49069V12.5325C7.16105 12.6615 7.05352 12.766 6.92088 12.766H6.44578C6.3169 12.766 6.211 12.6671 6.20581 12.542L5.95504 6.5001Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
      <path
        d="M9.80405 6.25723C9.94049 6.25723 10.0495 6.36758 10.044 6.5001L9.79325 12.542C9.78806 12.6671 9.68215 12.766 9.55328 12.766H9.07818C8.94554 12.766 8.83801 12.6615 8.83801 12.5325V6.49069C8.83801 6.36175 8.94554 6.25723 9.07818 6.25723H9.80405Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.28192 13.4472C3.28192 14.3032 4.05389 15.0036 4.99741 15.0036H11.0016C11.9452 15.0036 12.7171 14.3032 12.7171 13.4472L13.1351 4.13895C13.141 4.00614 13.0319 3.89531 12.8951 3.89531H3.10393C2.96718 3.89531 2.85802 4.00614 2.86399 4.13895L3.28192 13.4472ZM4.28434 12.724C4.28434 13.4272 4.89214 14.0025 5.635 14.0025H10.3623C11.1052 14.0025 11.713 13.4272 11.713 12.724L12.0506 5.05271H3.94667L4.28434 12.724Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
    </svg>
  );
};

export const CircleIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : 16}
      height={props.height ? props.height : 16}
      viewBox="0 0 16 16"
      fill={props.color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="7.5" stroke={props.outline} />
    </svg>
  );
};

export const CircleWithTickIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : 16}
      height={props.height ? props.height : 16}
      viewBox="0 0 16 16"
      fill={props.circleColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="7.5" stroke={props.circleOutline} />
      <path
        d="M6.35976 11.2621C6.56111 11.4566 6.88757 11.4566 7.08893 11.2621L13.1007 5.44215C13.302 5.24768 13.302 4.93238 13.1007 4.73791C12.8993 4.54344 12.5728 4.54344 12.3715 4.73791L7.08894 9.85365C6.88759 10.0481 6.56113 10.0481 6.35978 9.85365L3.62856 7.17253C3.4272 6.97806 3.10074 6.97806 2.89939 7.17253C2.69804 7.367 2.69804 7.6823 2.89939 7.87677L6.35976 11.2621Z"
        fill={props.tickColor}
      />
    </svg>
  );
};

export const CalenderIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : 18}
      height={props.height ? props.height : 18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99976 5C4.99976 5.13807 5.11168 5.25 5.24976 5.25H6.24976C6.38783 5.25 6.49976 5.13807 6.49976 5V0.5C6.49976 0.361929 6.38783 0.25 6.24976 0.25H5.24976C5.11168 0.25 4.99976 0.361929 4.99976 0.5L4.99976 2.5C4.99976 2.63807 4.88783 2.75 4.74976 2.75H1.5C0.809644 2.75 0.25 3.30964 0.25 4V16.5C0.25 17.1904 0.809645 17.75 1.5 17.75H16.5C17.1904 17.75 17.75 17.1904 17.75 16.5V4C17.75 3.30964 17.1904 2.75 16.5 2.75H14.875C14.7369 2.75 14.625 2.86193 14.625 3V4C14.625 4.13807 14.7369 4.25 14.875 4.25H16C16.1381 4.25 16.25 4.36193 16.25 4.5V7.5C16.25 7.63807 16.1381 7.75 16 7.75H2C1.86193 7.75 1.75 7.63807 1.75 7.5V4.5C1.75 4.36193 1.86193 4.25 2 4.25H4.74976C4.88783 4.25 4.99976 4.36193 4.99976 4.5V5ZM2 9.25C1.86193 9.25 1.75 9.36193 1.75 9.5V16C1.75 16.1381 1.86193 16.25 2 16.25H16C16.1381 16.25 16.25 16.1381 16.25 16V9.5C16.25 9.36193 16.1381 9.25 16 9.25H2Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
      <path
        d="M8.12476 3C8.12476 2.86193 8.23668 2.75 8.37476 2.75H11.25C11.3881 2.75 11.5 2.63807 11.5 2.5V0.5C11.5 0.361929 11.6119 0.25 11.75 0.25H12.75C12.8881 0.25 13 0.361929 13 0.5V5C13 5.13807 12.8881 5.25 12.75 5.25H11.75C11.6119 5.25 11.5 5.13807 11.5 5V4.5C11.5 4.36193 11.3881 4.25 11.25 4.25H8.37476C8.23668 4.25 8.12476 4.13807 8.12476 4V3Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
    </svg>
  );
};

export const DownloadIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : 14}
      height={props.height ? props.height : 14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.4488 6.46711C3.3707 6.389 3.3707 6.26237 3.4488 6.18427L3.87305 5.76001C3.95116 5.68191 4.07779 5.68191 4.15589 5.76001L6.4553 8.05933L6.4553 1.19805C6.4553 1.08759 6.54485 0.998047 6.6553 0.998047H7.2553C7.36576 0.998047 7.4553 1.08759 7.4553 1.19805L7.4553 8.05933L9.84468 5.66995C9.92279 5.59185 10.0494 5.59185 10.1275 5.66995L10.5518 6.0942C10.6299 6.17231 10.6299 6.29894 10.5518 6.37705L7.09672 9.8321C7.01862 9.9102 6.89199 9.9102 6.81388 9.8321L3.4488 6.46711Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
      <path
        d="M0 6.20198C0 6.09153 0.0895427 6.00198 0.2 6.00198H1.00052C1.11098 6.00198 1.20052 6.09153 1.20052 6.20198L1.20052 11.602C1.20052 11.7124 1.29006 11.802 1.40052 11.802L12.5995 11.802C12.7099 11.802 12.7995 11.7124 12.7995 11.602V6.20198C12.7995 6.09153 12.889 6.00198 12.9995 6.00198H13.8C13.9105 6.00198 14 6.09153 14 6.20198V12.802C14 12.9124 13.9105 13.002 13.8 13.002L0.2 13.002C0.0895429 13.002 0 12.9124 0 12.802V6.20198Z"
        fill={props.color ? props.color : "#4E4E4E"}
      />
    </svg>
  );
};

export const FilterIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : 24}
      height={props.height ? props.height : 18}
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 1.71734C0.5 0.975985 1.01864 0.375 1.65842 0.375H22.3416C22.9814 0.375 23.5 0.975985 23.5 1.71734C23.5 2.45869 22.9814 3.05967 22.3416 3.05967H1.65842C1.01864 3.05967 0.5 2.45869 0.5 1.71734Z"
        fill="#00a000"
      />
      <path
        d="M0.5 9.00045C0.5 8.2591 1.01864 7.65811 1.65842 7.65811H14.6539C15.2937 7.65811 15.8123 8.2591 15.8123 9.00045C15.8123 9.7418 15.2937 10.3428 14.6539 10.3428H1.65842C1.01864 10.3428 0.5 9.7418 0.5 9.00045Z"
        fill="#00a000"
      />
      <path
        d="M1.65842 14.9403C1.01864 14.9403 0.5 15.5413 0.5 16.2827C0.5 17.024 1.01864 17.625 1.65842 17.625H6.9662C7.60597 17.625 8.12462 17.024 8.12462 16.2827C8.12462 15.5413 7.60598 14.9403 6.9662 14.9403H1.65842Z"
        fill="#00a000"
      />
    </svg>
  );
};
