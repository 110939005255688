import { all } from "redux-saga/effects";
import appWatcher from "./appSaga";
import { authWatcher } from "./authSaga";
import { corporateWatcher } from "./corporateSaga";
import mockWatcher from "./mockSaga";
import { profileWatcher } from "./profileSaga";
import { statusWatcher } from "./statusSaga";

export default function* rootSaga() {
  yield all([
    authWatcher(),
    profileWatcher(),
    mockWatcher(),
    appWatcher(),
    statusWatcher(),
    corporateWatcher()
  ]);
}
