export const api = {
  baseUrl:
    process.env.REACT_APP_STAGING === "development"
      ? "https://dev.ninto.in/api"
      : process.env.REACT_APP_STAGING === "uat"
      ? "https://uat.ninto.in/api"
      : process.env.REACT_APP_STAGING === "production"
      ? "https://ninto.in/api"
      : "http://localhost:5001/karthealth-dev/asia-south1",

  appUrl:
    // eslint-disable-next-line
    process.env.REACT_APP_STAGING === "local"
      ? "http://localhost:3005"
      : "http://localhost:3005"
};

export function dateAndTimeConverter(timestamp, type) {
  if (!timestamp) return;

  if (type === "cardDate") {
    let dateObject = new Date(timestamp);
    const date =
      dateObject.toLocaleString("en-IN", { day: "numeric" }).toUpperCase() +
      " " +
      dateObject.toLocaleString("en-IN", { month: "short" }).toUpperCase() +
      " " +
      dateObject.toLocaleString("en-IN", { weekday: "long" }) +
      " ";
    return date;
  } else {
    if (type === "firstDate") {
      let dateObject = new Date(timestamp);
      const date = dateObject
        .toLocaleString("en-IN", { day: "numeric" })
        .toUpperCase();
      return date;
    }
    if (type === "secondDate") {
      let dateObject = new Date(timestamp);
      const date =
        dateObject.toLocaleString("en-IN", { day: "numeric" }).toUpperCase() +
        " " +
        dateObject.toLocaleString("en-IN", { month: "short" }).toUpperCase() +
        " " +
        dateObject.toLocaleString("en-IN", { year: "numeric" });
      return date;
    }
  }

  if (type === "Time") {
    let dateObject = new Date(timestamp);
    const date =
      dateObject
        .toLocaleString("en-IN", {
          hour: "numeric" && "2-digit",
          minute: "numeric" && "2-digit",
          hour12: true
        })
        .toUpperCase() + " ";
    return date;
  }
}

export const screen = {
  mobile: 567
};

export const validDocumentType = (data) => {
  let value;
  if (data) {
    switch (data) {
      case "OPConsultation":
        value = "OPConsultation";
        break;
      case "Prescription":
        value = "prescriptions";
        break;
      case "Prescriptions":
        value = "prescriptions";
        break;
      case "DischargeSummary":
        value = "admissions";
        break;
      case "DiagnosticReport":
        value = "reports";
        break;
      case "ImmunizationRecord":
        value = "ImmunizationRecord";
        break;
      case "HealthDocumentRecord":
        value = "HealthDocumentRecord";
        break;
      case "WellnessRecord":
        value = "WellnessRecord";
        break;
      case "prescription":
        value = "Prescription";
        break;
      case "admission":
        value = "DischargeSummary";
        break;
      case "report":
        value = "DiagnosticReport";
        break;
      default:
        break;
    }
  }
  return value;
};

export const remoteConfigStatus = {
  ALLOW_ALL: "ALLOW_ALL",
  UNDER_MAINTENANCE: "UNDER_MAINTENANCE",
  NOT_AVAILABLE: "NOT_AVAILABLE"
};

export const abdm_patient_auth_DEFAULT = {
  login: {
    status: remoteConfigStatus.ALLOW_ALL
  },
  signup: {
    status: remoteConfigStatus.ALLOW_ALL
  }
};

export const abdm_requests_DEFAULT = {
  status: remoteConfigStatus.ALLOW_ALL,
  consent: {
    write: true,
    read: true,
    status: remoteConfigStatus.ALLOW_ALL
  },
  locker: {
    write: true,
    read: true,
    status: remoteConfigStatus.ALLOW_ALL
  },
  authorization: {
    write: true,
    read: true,
    status: remoteConfigStatus.ALLOW_ALL
  },
  subscription: {
    write: true,
    read: true,
    status: remoteConfigStatus.ALLOW_ALL
  }
};

export const abdm_consent_pin_DEFAULT = {
  status: remoteConfigStatus.ALLOW_ALL
};

export const abdm_find_clinic_DEFAULT = {
  status: remoteConfigStatus.UNDER_MAINTENANCE
};

export const abdm_documents_DEFAULT = {
  status: remoteConfigStatus.ALLOW_ALL,
  symptom: {
    write: true,
    read: true,
    status: remoteConfigStatus.ALLOW_ALL
  },
  prescription: {
    write: true,
    read: true,
    status: remoteConfigStatus.ALLOW_ALL
  },
  report: {
    write: true,
    read: true,
    status: remoteConfigStatus.ALLOW_ALL
  },
  receipt: {
    write: true,
    read: true,
    status: remoteConfigStatus.ALLOW_ALL
  },
  admission: {
    write: true,
    read: true,
    status: remoteConfigStatus.ALLOW_ALL
  },
  insurance: {
    write: true,
    read: true,
    status: remoteConfigStatus.ALLOW_ALL
  },
  vitals: {
    write: true,
    read: true,
    status: remoteConfigStatus.ALLOW_ALL
  }
};

export const abdm_connect_patient_clinic_DEFAULT = {
  status: remoteConfigStatus.ALLOW_ALL
};
