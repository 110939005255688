import { all, put, takeEvery } from "@redux-saga/core/effects";
import { otpRequest, otpValidate } from "../../services/authentication";
import store from "../store/store";
import { authActions } from "./authSaga";
import { statusActions } from "./statusSaga";
import { getVerificationCodeFromEmulator } from "../../services/api.mock";

export const actionTypes = {
  MOCK_AUTH: "MOCK_AUTH",
  MOCK_AUTH_VERIFY_OTP: "MOCK_AUTH_VERIFY_OTP"
};

export const mockActions = {
  mockAuth: (type) => {
    store.dispatch({
      type: actionTypes.MOCK_AUTH,
      payload: {
        type: type
      }
    });
  },

  mockAuthVerifyOtp: (loginOtp, userName, phoneNumber, type) => {
    store.dispatch({
      type: actionTypes.MOCK_AUTH_VERIFY_OTP,
      payload: {
        loginOtp: loginOtp,
        userName: userName,
        phoneNumber: phoneNumber,
        type: type
      }
    });
  }
};

function* mockAuthWorker(action) {
  try {
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: true
      }
    });
    const phoneNumber = "+911234524609";
    const defaultOTP = "000000";
    const verificationId = yield otpRequest(phoneNumber);
    yield put({
      type: "SET_AUTH_VERIFICATION_ID",
      payload: {
        verificationId: verificationId
      }
    });
    if (process.env.REACT_APP_STAGING === "local") {
      mockActions.mockAuthVerifyOtp();
    } else {
      yield put({
        type: "SET_AUTH_LOADING",
        payload: {
          loading: false
        }
      });
      yield put({
        type: "VERIFY_OTP",
        payload: {
          loginOtp: defaultOTP,
          userName: "chan",
          phoneNumber: phoneNumber,
          type: action.payload.type
        }
      });
    }
  } catch (error) {
    yield statusActions.setErrorStatus(error);
  }
}

function* mockAuthVerifyOtpWorker(action) {
  try {
    const otpResponse = yield getVerificationCodeFromEmulator();
    const verificationCode =
      otpResponse.verificationCodes[otpResponse.verificationCodes.length - 1];
    if (verificationCode) {
      const authCredential = yield otpValidate(
        verificationCode.code,
        store.getState().auth.credentials.verificationId
      );
      yield put({
        type: "SET_AUTH_CREDENTIALS",
        payload: {
          authCredential: authCredential
        }
      });

      if (action.payload.type === "signUp") {
        authActions.signUp(
          action.payload.displayName,
          action.payload.phoneNumber
        );
      } else {
        authActions.login();
      }
    }
  } catch (error) {
    yield statusActions.setErrorStatus(error);
  }
}

export default function* mockWatcher() {
  yield all([
    takeEvery("MOCK_AUTH", mockAuthWorker),
    takeEvery("MOCK_AUTH_VERIFY_OTP", mockAuthVerifyOtpWorker)
  ]);
}
