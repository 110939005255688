import store from "../redux/store/store";
import { throwError } from "../services/error";

export const isStoreHasAllProfiles = (data) => {
  if (
    data &&
    typeof data === "object" &&
    Object.values(data).every((profile) => {
      return (
        profile.hasOwnProperty("escalationContact") &&
        profile.hasOwnProperty("public")
      );
    })
  ) {
    return true;
  } else {
    return false;
  }
};

export const isValidObject = (object) => {
  if (Array.isArray(object)) return false;
  return (
    object !== null &&
    object !== undefined &&
    typeof object === "object" &&
    Object.keys(object).length > 0
  );
};

export const isValidArray = (array) => {
  return Array.isArray(array) && array.length > 0;
};

export const isValidString = (text) => {
  return typeof text === "string" && text.trim().length > 0;
};

export const isOnline = () => {
  if (store.getState().app.online) {
    return true;
  } else {
    throw throwError("custom", "Currently offline,please connect to internet");
  }
};
