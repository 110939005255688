import { put, takeEvery, all } from "redux-saga/effects";
import {
  getPartneredClinics,
  setAppointmentForHealthCheckup,
  updateCheckupName
} from "../../services/database";
import store from "../store/store";
import { bucketNames, getFileUrl } from "../../services/storage";
import { statusActions } from "./statusSaga";
import { isOnline, isValidArray } from "../../utils/validators";

export const actionTypes = {
  SET_CORPORATE_CHECKUPS: "SET_CORPORATE_CHECKUPS",
  ADD_NAME_TO_CHECKUPS: "ADD_NAME_TO_CHECKUPS",
  SET_SELECTED_REQUEST: "SET_SELECTED_REQUEST",
  CLEAR_SELECTED_REQUEST: "CLEAR_SELECTED_REQUEST",
  GET_AVAILABLE_CLINICS: "GET_AVAILABLE_CLINICS",
  SET_SELECTED_CLINIC: "SET_SELECTED_CLINIC",
  SET_CHECKUP_APPOINTMENT: "SET_CHECKUP_APPOINTMENT",
  GET_DOWNLOAD_URL_FOR_REPORT: "GET_DOWNLOAD_URL_FOR_REPORT",
  GET_CLINIC_LOGO: "GET_CLINIC_LOGO",
  GET_CORPORATE_LOGO: "GET_CORPORATE_LOGO"
};

export const corporateActions = {
  corporateCheckups: (checkups) => {
    store.dispatch({
      type: actionTypes.SET_CORPORATE_CHECKUPS,
      payload: {
        checkups: checkups
      }
    });
  },

  setSelectedRequest: (request) => {
    store.dispatch({
      type: actionTypes.SET_SELECTED_REQUEST,
      payload: {
        data: request
      }
    });
  },

  addNameToCheckups: (name) => {
    store.dispatch({
      type: actionTypes.ADD_NAME_TO_CHECKUPS,
      payload: {
        data: name
      }
    });
  },

  getAvailableClinics: (corporateBrandId) => {
    store.dispatch({
      type: actionTypes.GET_AVAILABLE_CLINICS,
      payload: {
        corporateBrandId: corporateBrandId
      }
    });
  },

  setAppointmentForCheckup: (requestId, data) => {
    store.dispatch({
      type: actionTypes.SET_CHECKUP_APPOINTMENT,
      payload: {
        requestId: requestId,
        appointmentData: data
      }
    });
  },

  clearSelectedRequest: () => {
    store.dispatch({
      type: actionTypes.CLEAR_SELECTED_REQUEST
    });
  },

  setSelectedClinic: (selectedClinic) => {
    store.dispatch({
      type: actionTypes.SET_SELECTED_CLINIC,
      payload: {
        selectedClinic: selectedClinic
      }
    });
  },

  setClinicLogo: () => {
    store.dispatch({
      type: actionTypes.GET_CLINIC_LOGO
    });
  },

  setCorporateLogo: (data) => {
    store.dispatch({
      type: actionTypes.GET_CORPORATE_LOGO,
      payload: {
        data: data
      }
    });
  },

  getReportDownloadAbleURL: (data) => {
    store.dispatch({
      type: actionTypes.GET_DOWNLOAD_URL_FOR_REPORT,
      payload: {
        data: data
      }
    });
  }
};

function* setCorporateCheckupsWorker(action) {
  try {
    yield put({
      type: "SET_CORPORATE_CHECKUPS_DATA",
      payload: {
        checkups: action.payload.checkups
      }
    });
  } catch (error) {
    yield setCorporateLoading(false);
    statusActions.setErrorStatus(error);
  }
}

function* addCheckupsWorker(action) {
  try {
    if (isOnline()) {
      const checkups = store.getState().corporate.checkups;

      if (!isValidArray(checkups)) {
        return;
      }

      if (checkups.some((value) => (!value.name ? true : false))) {
        yield setCorporateLoading(true);
        // eslint-disable-next-line no-unused-vars
        for (const [index, value] of checkups.entries()) {
          if (!value.name) {
            yield updateCheckupName(value.requestId, {
              name: action.payload.data
            });
          }
        }
      }
      yield setCorporateLoading(false);
    }
  } catch (error) {
    yield setCorporateLoading(false);
    statusActions.setErrorStatus(error);
  }
}

function* setSelectedRequestWorker(action) {
  try {
    yield setCorporateLoading(true);
    yield put({
      type: "SET_SELECTED_REQUEST_DATA",
      payload: {
        data: action.payload.data
      }
    });
    yield setCorporateLoading(false);
  } catch (error) {
    yield setCorporateLoading(false);
    statusActions.setErrorStatus(error);
  }
}

function* getClinicLogoWorker(action) {
  try {
    if (isOnline()) {
      let clinicBrandId = {};

      for (let data of Object.values(
        store.getState().corporate.availableClinics
      )) {
        clinicBrandId = {
          ...clinicBrandId,
          [data.brandId]: {
            documentId: data.brandId,
            url: yield getFileUrl(
              `gs://${bucketNames.nintoProfilePictures}/clinicBrands/${data.brandId}.png`
            )
          }
        };
      }
      yield put({
        type: "SET_CLINIC_LOGO",
        payload: {
          clinicBrandId: clinicBrandId
        }
      });
    }
  } catch (error) {
    if (error.toString().includes("does not exist")) {
      console.warn(error);
    } else {
      statusActions.setErrorStatus(error);
    }
  }
}

function* getCorporateLogoWorker(action) {
  try {
    if (isOnline()) {
      const corporateBrandIds = action.payload?.data?.filter(
        (item, index) => action.payload?.data?.indexOf(item) === index
      );
      let corporateBrandInfo = {};

      for (let data of corporateBrandIds) {
        corporateBrandInfo = {
          ...corporateBrandInfo,
          [data]: {
            downloadUrl: yield getFileUrl(
              `gs://${bucketNames.nintoProfilePictures}/corporateBrands/${data}.png`
            )
          }
        };
      }
      yield put({
        type: "SET_CORPORATE_LOGO",
        payload: {
          corporateBrandLogo: corporateBrandInfo
        }
      });
    }
  } catch (error) {
    if (error.toString().includes("does not exist")) {
      console.warn(error);
    } else {
      statusActions.setErrorStatus(error);
    }
  }
}

function* clearSelectedRequestWorker() {
  yield put({
    type: "RESET_SELECTED_REQUEST"
  });
}

function* getAllPartneredClinicsWorker(action) {
  try {
    if (isOnline()) {
      const clinics = yield getPartneredClinics(
        action.payload.corporateBrandId
      );

      yield put({
        type: "SET_AVAILABLE_CLINICS",
        payload: {
          clinics: clinics
        }
      });
      if (clinics.length !== 0) {
        yield corporateActions.setClinicLogo();
      }
    }
  } catch (error) {
    yield setCorporateLoading(false);
    statusActions.setErrorStatus(error);
  }
}

function* setAppointmentForCheckupWorker(action) {
  try {
    if (isOnline()) {
      yield setCorporateLoading(true);
      yield setAppointmentForHealthCheckup(
        action.payload.requestId,
        action.payload.appointmentData
      );
      statusActions.setSuccessStatus("Appointment Successfully saved");
      yield setCorporateLoading(false);
    }
  } catch (error) {
    statusActions.setErrorStatus(error);
    yield setCorporateLoading(false);
  }
}

function* setSelectedClinicWorker(action) {
  try {
    yield setCorporateLoading(true);

    yield put({
      type: "SET_SELECTED_CLINIC_DATA",
      payload: {
        selectedClinic: action.payload.selectedClinic
      }
    });

    yield setCorporateLoading(false);
  } catch (error) {
    statusActions.setErrorStatus(error);
    yield setCorporateLoading(false);
  }
}

function* getUploadURLForReportWorker(action) {
  try {
    if (isOnline()) {
      let URL;
      const fileName = "default.json";
      const fileType = "DiagnosticReport";
      const documentId = action.payload.data.documentId;
      const clinicId = action.payload.data.clinicId;
      const patientDemographicDocumentId = action.payload.data.patientId;
      URL = yield getFileUrl(
        action.payload.data.url === undefined
          ? `gs://dev-ninto-documents-storage-1/documents/${clinicId}/${patientDemographicDocumentId}/${fileType}/${documentId}/${fileName}`
          : action.payload.data.url
      );

      yield put({
        type: "SET_CHECKUP_DOWNLOADABLE_URL",
        payload: {
          data: {
            ...(typeof URL === "string" ? { [documentId]: URL } : {})
          }
        }
      });
    }
  } catch (error) {
    yield statusActions.setErrorStatus(error);
  }
}

export function* corporateWatcher() {
  yield all([
    takeEvery("SET_CORPORATE_CHECKUPS", setCorporateCheckupsWorker),
    takeEvery("ADD_NAME_TO_CHECKUPS", addCheckupsWorker),
    takeEvery("SET_SELECTED_REQUEST", setSelectedRequestWorker),
    takeEvery("CLEAR_SELECTED_REQUEST", clearSelectedRequestWorker),
    takeEvery("GET_AVAILABLE_CLINICS", getAllPartneredClinicsWorker),
    takeEvery("SET_SELECTED_CLINIC", setSelectedClinicWorker),
    takeEvery("SET_CHECKUP_APPOINTMENT", setAppointmentForCheckupWorker),
    takeEvery("GET_DOWNLOAD_URL_FOR_REPORT", getUploadURLForReportWorker),
    takeEvery("GET_CLINIC_LOGO", getClinicLogoWorker),
    takeEvery("GET_CORPORATE_LOGO", getCorporateLogoWorker)
  ]);
}

function* setCorporateLoading(bool) {
  yield put({
    type: "SET_CORPORATE_LOADING",
    payload: {
      loading: bool
    }
  });
}
