import {
  ref,
  uploadBytes,
  deleteObject,
  getDownloadURL
} from "firebase/storage";
import { switchBucket } from "./firebase";
export const bucketNames = {
  providerIdentityDocuments:
    process.env.REACT_APP_STAGING === "production"
      ? "prod-ninto-provider-identity-documents"
      : process.env.REACT_APP_STAGING === "uat"
      ? "uat-ninto-provider-identity-documents"
      : "dev-ninto-provider-identity-documents",
  nintoProfilePictures:
    process.env.REACT_APP_STAGING === "production"
      ? "prod-ninto-profile-pictures"
      : process.env.REACT_APP_STAGING === "uat"
      ? "uat-ninto-profile-pictures"
      : "dev-ninto-profile-pictures",
  documentsUpload:
    process.env.REACT_APP_STAGING === "production"
      ? "prod-ninto-documents-upload"
      : process.env.REACT_APP_STAGING === "uat"
      ? "uat-ninto-documents-upload"
      : "dev-ninto-documents-upload",
  abdmDocumentsUpload:
    process.env.REACT_APP_STAGING === "production"
      ? "prod-abdm-documents-upload"
      : process.env.REACT_APP_STAGING === "uat"
      ? "uat-abdm-documents-upload"
      : "dev-abdm-documents-upload",
  prescriptionTemplates:
    process.env.REACT_APP_STAGING === "production"
      ? "prod-ninto-prescription-templates"
      : process.env.REACT_APP_STAGING === "uat"
      ? "uat-ninto-prescription-templates"
      : "dev-ninto-prescription-templates"
};

//for logo at the edit profile
export async function uploadProfilePicture(file, id) {
  const customStorage = switchBucket(
    "gs://" + bucketNames.nintoProfilePictures
  );
  const profilePicture = await uploadBytes(
    ref(customStorage, `/corporateBrands/${id}.png`),
    file
  );
  return profilePicture;
}

// for uploading document
export async function uploadDocuments(
  clinicId,
  patientDemographicDocumentId,
  fileType,
  file,
  metaData
) {
  const customStorage = switchBucket("gs://" + bucketNames.abdmDocumentsUpload);
  const fileName = file.name ? file.name : file.size;
  const documents = await uploadBytes(
    ref(
      customStorage,
      `/documents/${clinicId}/${patientDemographicDocumentId}/${fileType}/${fileName}`
    ),
    file,
    {
      customMetadata: metaData
    }
  );
  return documents;
}

// for uploading image document

export async function uploadImageDocument(
  patientId,
  fileType,
  file,
  patientPhoneNumber,
  metaData
) {
  const customStorage = switchBucket(`gs://${bucketNames.documentsUpload}`);
  const fileName = file.name ? file.name : file.size;
  await uploadBytes(
    ref(
      customStorage,
      `documents/${patientPhoneNumber}/${patientId}/${fileType}/${fileName}`
    ),
    file,
    {
      customMetadata: {
        ...metaData
      }
    }
  );
}

export async function deleteDocumentStorage(fileURL) {
  const slashElementIndex = fileURL.indexOf("/", 12);
  const fileName = fileURL.substring(slashElementIndex + 1, fileURL.length);
  const bucketName = fileURL.substring(0, slashElementIndex);
  const customStorage = switchBucket(bucketName);
  const removeDocument = ref(customStorage, fileName);
  // Delete the file
  try {
    await deleteObject(removeDocument);
    return removeDocument;
  } catch (error) {
    console.error(error);
  }
}

export async function getFileUrl(storageLocation) {
  const slashElementIndex = storageLocation.indexOf("/", 12);
  const fileName = storageLocation.substring(
    slashElementIndex + 1,
    storageLocation.length
  );
  const bucketName = storageLocation.substring(0, slashElementIndex);
  const customStorage = switchBucket(bucketName);
  const url = await getDownloadURL(ref(customStorage, fileName));
  return url;
}
