import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Login from "./screens/Login/Login";
import Home from "./screens/Home/Home";
import SnackBar from "./front-end-global-components/components/SnackBar/SnackBar";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  useCorporateCheckupsListener,
  useProfileListener
} from "./services/database";
import LogRocket from "logrocket";
import { useAuthSubscriber } from "./services/authentication";
import Modal from "./front-end-global-components/components/Modal/Modal";
import Button from "./front-end-global-components/components/Button/Button";
import InputBox from "./front-end-global-components/components/InputBox/InputBox";
import { profileActions } from "./redux/sagas/profileSaga";
import { isValidObject } from "./front-end-global-components/services/validators";
import SelectedRequest from "./screens/SelectedRequest/SelectedRequest";
import { corporateActions } from "./redux/sagas/corporateSaga";
import ViewDocument from "./screens/ViewDocument/ViewDocument";
import reloadUpdate from "./front-end-global-components/assets/reload_update.svg";
import { appActions } from "./redux/sagas/appSaga";

function App(props) {
  const [status, setStatus] = useState(null);
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
  const [showAddUserNameModal, setShowAddUserNameModal] = useState(false);
  const [addUserNameFormData, setAddUserNameFormData] = useState({
    userName: ""
  });
  const isAuthed = useAuthSubscriber();
  const navigate = useNavigate();
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const getWindowSize = () => {
    setWindowInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("resize", getWindowSize);
    return () => {
      window.removeEventListener("resize", getWindowSize);
    };
    // eslint-disable-next-line
  }, []);

  const getNetworkStatusChange = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener("online", getNetworkStatusChange);
    window.addEventListener("offline", getNetworkStatusChange);
    return () => {
      window.removeEventListener("online", getNetworkStatusChange);
      window.removeEventListener("offline", getNetworkStatusChange);
    };
    // eslint-disable-next-line
  }, [isOnline]);

  useEffect(() => {
    props.setIsOnline(isOnline);
    // eslint-disable-next-line
  }, [isOnline]);

  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      window.location.hostname !== "localhost"
    ) {
      const release =
        typeof process.env.REACT_APP_BUILD_NUM === "string"
          ? { release: process.env.REACT_APP_BUILD_NUM }
          : {};

      switch (process.env.REACT_APP_STAGING) {
        case "uat":
          LogRocket.init("byepo/corporate-patients-uat", release);
          break;

        case "dev":
          LogRocket.init("byepo/corporate-patients-dev", release);
          break;

        default:
          break;
      }
    }
  }, []);

  useProfileListener({
    uid: props.auth.data.uid,
    phoneNumber: props.auth.data.phoneNumber,
    isAuthed: isAuthed
  });

  useCorporateCheckupsListener({
    phoneNumber: props.auth.data.phoneNumber,
    isAuthed: isAuthed
  });

  useEffect(() => {
    if (!props.auth.data.uid) {
      return;
    }
    if (!isValidObject(props.profile.data)) {
      return;
    }
    if (
      isAuthed &&
      (props.profile.data[Object.keys(props.profile.data)[0]]?.name ===
        undefined ||
        props.profile.data[Object.keys(props.profile.data)[0]]?.name === null)
    ) {
      setShowAddUserNameModal(true);
    } else {
      setShowAddUserNameModal(false);
    }
  }, [props.profile.data, props.auth.data, isAuthed]);

  useEffect(() => {
    if (
      props.profile.data &&
      props.profile.data[Object.keys(props.profile.data)[0]]?.name
    ) {
      props.addNameToCheckups(
        props.profile.data[Object.keys(props.profile.data)[0]]?.name
      );
    }
    // eslint-disable-next-line
  }, [props.corporate.checkups, props.profile.data]);

  //useEffect for error handling
  useEffect(() => {
    if (props.status.message) {
      setStatus(props.status.message);
    } else {
      setStatus(null);
    }
  }, [props.status]);

  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute uid={props.auth.data.uid}>
              <Login windowInnerWidth={windowInnerWidth} navigate={navigate} />
            </PublicRoute>
          }
        />

        <Route
          path="/"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <Home
                windowInnerWidth={windowInnerWidth}
                navigate={navigate}
                isNamePresent={showAddUserNameModal}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="/documents/:documentId"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <ViewDocument
                windowInnerWidth={windowInnerWidth}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="/request"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <SelectedRequest
                windowInnerWidth={windowInnerWidth}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Modal
        show={showAddUserNameModal}
        canIgnore={false}
        onClose={() => {
          setShowAddUserNameModal(!showAddUserNameModal);
        }}
        position={
          windowInnerWidth > 576 ? "" : "position-fixed bottom-0 left-0 right-0"
        }
        width="inherit-parent-width"
        maxWidth={windowInnerWidth < 576 ? "" : "max-width-500px"}
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-fit-to-content"
      >
        <div
          className={`background-white padding-larger ${
            props.windowInnerWidth < 576
              ? "border-radius-top-default"
              : "border-radius-default"
          } box-shadow-default font-family-gilroy-regular font-color-secondary`}
        >
          <div className=" text-align-center font-family-gilroy-medium">
            Welcome! What is your name?
          </div>
          <form
            onChange={(event) => {
              setAddUserNameFormData({
                ...addUserNameFormData,
                [event.target.name]: event.target.value
              });
            }}
            onSubmit={(event) => {
              event.preventDefault();
              props.editProfile(addUserNameFormData);
              setShowAddUserNameModal(false);
            }}
          >
            <InputBox
              className="inherit-parent-width margin-top-larger"
              label="Your name"
              type="text"
              name="userName"
              removeResponsive={true}
              notes="Please enter name as per your official ID"
              value={addUserNameFormData.userName}
            />
            <Button
              data-cy="continueButton"
              boxShadow={false}
              disabled={addUserNameFormData.userName ? false : true}
              className="margin-top-larger font-family-gilroy-medium"
              variant="primary"
              text="Continue"
              type="submit"
            />
          </form>
        </div>
      </Modal>
      <SnackBar
        message={status}
        status={props.status}
        type={props.status.code === null ? "success" : "error"}
      />
      <div
        id="critical-update-card"
        className=" display-none inherit-parent-height inherit-parent-width background-color-black-with-opacity-light z-index-101 position-absolute-center-self "
      >
        <footer className="position-absolute  inherit-parent-width bottom-0 padding-large">
          <div
            id="critical-update-card-contents-wrapper"
            className="background-color-white inherit-parent-width padding-large border-radius-default display-flex flex-direction-column flex-align-items-center "
          >
            <img
              src={reloadUpdate}
              alt="update"
              className=" margin-bottom-default"
            />
            <p className="font-family-gilroy-medium font-size-medium font-color-secondary text-align-center margin-bottom-default ">
              {" "}
              Critical update available{" "}
            </p>
            <p className="font-family-gilroy-regular font-size-medium font-color-secondary text-align-center margin-bottom-default ">
              New critical changes updated and ready to launch.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}

const ProtectedRoute = ({ uid, profileData, children }) => {
  if (uid === null && profileData === null) {
    return <Navigate to="/login" />;
  }
  return children;
};

const PublicRoute = ({ uid, children }) => {
  if (typeof uid === "string") {
    return <Navigate to="/" />;
  }
  return children;
};

const mapStateToProps = function (state) {
  return {
    status: state.status,
    auth: state.auth,
    profile: state.profile,
    corporate: state.corporate
  };
};

const mapDispatchToProps = function () {
  return {
    editProfile: (data) => profileActions.editProfile(data),
    addNameToCheckups: (data) => corporateActions.addNameToCheckups(data),
    setIsOnline: (data) => appActions.putOnline(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
